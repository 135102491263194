import { Component } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { Location } from '@angular/common';
import { CustomerService } from '../../../services/customer/customer.service';
import moment from 'moment';
import { ActivatedRoute } from '@angular/router';
import { CommonModule } from '@angular/common';
import { SettingsService } from '../../../services/shared/settings.service';
import { environment } from '../../../environments/environment';
import { CartService } from '../../../services/cart/cart.service';
import { StorageService } from '../../../services/storage/storage.service';
import { SESSION_DEPARTMENT_LIST, SESSION_NEAREST_SHOP_LIST, SESSION_SHOP_LIST, SESSION_SYSTEM_SETTINGS } from '../../../app.constants';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import html2canvas from 'html2canvas';
import { MatDialog } from '@angular/material/dialog';
import { OrderScheduleComponent } from '../../order-schedule/order-schedule.component';
import { UtilService } from '../../../services/shared/util.service';
import { OrderHistoryService } from '../../../services/shared/order-history.service';
import { MessageDialogService,NotificationDialogModel } from '../../../services/shared/message-dialog.service';

@Component({
  selector: 'app-my-order-details',
  standalone: true,
  imports: [RouterModule, CommonModule],
  templateUrl: './my-order-details.component.html',
  styleUrl: './my-order-details.component.scss',
})
export class MyOrderDetailsComponent {
  receiptMail: boolean;
  htmlData!: SafeHtml;
  result: any;
  orderInfoDetail: any;
  orderDetailsInfo: any;
  orderHistoryDetail: any;
  orderId: any;
  orderDetailArray: any[] = [];
  imageUrl: string = '';
  systemSettings: any;
  currencySymbol: any;
  totalQuantity: number | undefined;
  shopList: any;
  msg: any;
  id: any;
  shop: any;
  departmentList: any;
  localserverTime: any;
  allShopList: any;
  day: any;
  shoptime: any;
  hasNearestShop: boolean = false;
  nearestShopList: any;
  recentOrderObject: any;
  src: any;
  itemTotalComboWithCustomization!: number;
  totalPriceAfterDiscount: number =0;
  totalAmountWithCustomization: number =0;
  totalDiscountAmount: number =0;
  constructor(
    private router: Router,
    private location: Location,
    private customerservice: CustomerService,
    private route: ActivatedRoute,
    private _settingsService: SettingsService,
    private cartService: CartService,
    private storageService: StorageService,
    private settingsService: SettingsService,
    private sanitizer: DomSanitizer,
    public dialog: MatDialog,
    private utilService:UtilService, 
    private orderHistoryService:OrderHistoryService,
    private messageDialogService:MessageDialogService
  ) {
    this.id = this.route.snapshot.paramMap.get('id');
    this.orderId = this.route.snapshot.queryParams['id'];
    this.src = this.route.snapshot.queryParams['src'];
    this.getOrderHistoryDetail(this.orderId);
    this.imageUrl = environment.oos.assetsUrl;
    this.systemSettings = JSON.parse(
      this.storageService.loadFromSession(SESSION_SYSTEM_SETTINGS)
    );
    this.currencySymbol = this.systemSettings.currency.symbol;
    if (this.systemSettings === null || this.systemSettings === undefined) {
      this.settingsService.loadSystemSettings().subscribe((res) => {
        this.systemSettings = res.object;
        this.storageService.saveToSession(
          SESSION_SYSTEM_SETTINGS,
          JSON.stringify(this.systemSettings)
        );
        this.currencySymbol = this.systemSettings.currency.symbol;
      });
    }
    this.receiptMail = false;
  }

  back() {
    this.location.back();
  }
/**
 * 
 * to get order details
 */

  getOrderHistoryDetail(orderId: any): void {
    this.customerservice.getMyOrderDetail(orderId).subscribe({
      next: (response) => {
        this.result = response.success;
        if (this.result) {
          response=JSON.parse(JSON.stringify(response));
          this.orderInfoDetail = response.object.orderInfo;
          this.orderInfoDetail.deliveryTime = moment(
            this.orderInfoDetail.deliveryTime,
            'HH:mm:ss'
          ).format('LT');
          this.orderDetailsInfo = response.object.orderDtl;
          this.orderHistoryDetail = response.object.orderHistory; 
          // this.gtmService.pushEvent(
          //   'previous_orders_details',
          //   {
          //     userId: this.gtmService.getCustomer(),
          //     previousStore: this.orderInfoDetail.shopName,
          //     previousDate: this.orderInfoDetail.deliveryDate,
          //     previousTime: this.orderInfoDetail.deliveryTime
          //   });

          this.getOrderDetailAllInfo();
        }
      },
      error: (error) => {},
    });
  }

  ngOnInit() {
    this.departmentList = JSON.parse(this.storageService.loadFromSession(SESSION_DEPARTMENT_LIST));
    this.utilService.scrollToTop();
    this.localserverTime = new Date();
    this.id = this.route.snapshot.paramMap.get('id');
    this.allShopList = JSON.parse(this.storageService.loadFromSession(SESSION_SHOP_LIST));
    this.shop = this.allShopList.find((s: any) => s.id == this.id);
    // this.storageService.saveToSession(SESSION_SELECTED_SHOP_LIST,JSON.stringify(this.shop));
    const weekday = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    this.day = weekday[this.localserverTime.getDay()];
    const getColonTimeFromDate = (date: any) => date.toTimeString().slice(0, 8);
    this.shoptime = getColonTimeFromDate(new Date(this.localserverTime + 1000 * 60 * 30 * 7));
   
    this.allShopList = JSON.parse(this.storageService.loadFromSession(SESSION_SHOP_LIST));
    this.nearestShopList = JSON.parse(this.storageService.loadFromSession(SESSION_NEAREST_SHOP_LIST));
    this.departmentList = JSON.parse(this.storageService.loadFromSession(SESSION_DEPARTMENT_LIST));
    // if (this.nearestShopList == '') {
      this.shopList = this.allShopList;
  }
/**
 * 
 * to display order date 
 * 
 */
  getDisplayDate(orderInfo: any): string {
    if (!orderInfo || !orderInfo.deliveryDate) {
      return 'Delivery date unavailable'; // Handle missing data gracefully
    }

    try {
      const parsedDate = moment(
        orderInfo.deliveryDate.split(' ')[0],
        'YYYY-MM-DD'
      );
      return parsedDate.format('DD-MMM-YYYY'); // Use YYYY for full year format
    } catch (error) {
      console.error('Error parsing delivery date:', error);
      return 'Invalid delivery date format'; // Provide informative error handling
    }
  }

  getCustomizationInfo(orderDetails:any):any{
    // for (let element of this.orderDetailsInfo) {
      this.totalDiscountAmount = orderDetails.discountAmount;
      this.totalAmountWithCustomization=orderDetails.fixedPrice;
      this.totalPriceAfterDiscount=(orderDetails.qty*orderDetails.fixedPrice)-orderDetails.discountAmount
      if (orderDetails.customizations !== null && orderDetails.customizations.length > 0) {
        const customizations = orderDetails.customizations;
        var cusTotalOfferAmount=0
        var cusTotalAmount=0
        for (let cu of orderDetails.customizations) {
          if(cu.offerAmount !== undefined){
            cusTotalOfferAmount = cusTotalOfferAmount+cu.offerAmount;
            let totalCustWithQty=cu.unitPriceWithTax;
            cusTotalAmount=cusTotalAmount+totalCustWithQty
          }
        }
        this.totalAmountWithCustomization=orderDetails.fixedPrice+cusTotalAmount;
        this.totalPriceAfterDiscount=(orderDetails.qty*this.totalAmountWithCustomization)-(orderDetails.discountAmount+cusTotalOfferAmount);
        // console.log(cusTotalAmount)
        this.totalDiscountAmount += cusTotalOfferAmount;
        // return this.totalDiscountAmount;
      }
    // }
  }

  getOrderDetailAllInfo(){
    this.orderDetailArray = [];
    this.totalQuantity = 0;
    for (let element of this.orderDetailsInfo) {
      const orderDetailPerItem = element;
      this.totalQuantity += element.qty;
      orderDetailPerItem.isShow = false;
      orderDetailPerItem.itemTotalWithCustomization = this.cartService.getUnitpriceWithTax(element.taxCalculationMethod, element.fixedPrice, element.tax1Pc);
      if (element.remarks !== null) {
        orderDetailPerItem.remarks = (element.remarks.toString().trim().length > 0) ? '*' + element.remarks : '';
      }
      if (orderDetailPerItem.customizations !== null && orderDetailPerItem.customizations.toString().trim().length > 0) {
        orderDetailPerItem.customizations = JSON.parse(orderDetailPerItem.customizations);
        // this.getCustomizationInfo(orderDetailPerItem)
        const mainItem = {
          'id': element.id,
          'orderId': element.orderId,
          'parentDtlId': '',
          'optionValueNameToPrint': element.name,
          'fixedPrice': element.fixedPrice,
          'saleItemId': element.saleItemId,
          'taxCalculationMethod': element.taxCalculationMethod,
          'tax1Percentage': element.tax1Pc,
          'unitPriceWithTax': this.cartService.getUnitpriceWithTax(element.taxCalculationMethod, element.fixedPrice, element.tax1Pc)
        }
        orderDetailPerItem.customizations.unshift(mainItem);
        orderDetailPerItem.itemTotalWithCustomization = this.getItemWithCustomTotalPrice(orderDetailPerItem.customizations);
      }
      let totalUnitPriceWithTax = 0;
      if (orderDetailPerItem.comboOptions !== null && orderDetailPerItem.comboOptions.toString().trim().length > 0) {
        orderDetailPerItem.comboOptions = JSON.parse(orderDetailPerItem.comboOptions);
        
        for (const comboOption of orderDetailPerItem.comboOptions) {
          if (comboOption.unitPriceWithTax) {
            totalUnitPriceWithTax += comboOption.unitPriceWithTax;
          }
          if (comboOption.customizations) {
            comboOption.customizations = JSON.parse(comboOption.customizations);
            for (const customization of comboOption.customizations) {
              if (customization.unitPriceWithTax) {
                totalUnitPriceWithTax += customization.unitPriceWithTax;
              }
            }
          }
        }
       
        // for (const comboOptions of orderDetailPerItem.comboOptions) {
        //   if (comboOptions.customizations) {
        //     comboOptions.customizations = JSON.parse(comboOptions.customizations);
        //     orderDetailPerItem.itemTotalWithComboCustomization = this.getItemWithCuomboTotalPrice(comboOptions.customizations);
        //   }
        // }
        
        
        orderDetailPerItem.itemTotalWithComboCustomization = totalUnitPriceWithTax;
    
      }

      this.orderDetailArray.push(orderDetailPerItem);
    }
  }
  

  /**
   * sum up main item and customization item prices.
   * @param itemCustomization 
   * @returns 
   */
  getItemWithCustomTotalPrice(itemCustomization:any): any {
    var customizationpriceTotal = 0;
    for (let subItem of itemCustomization) {
      customizationpriceTotal += subItem.unitPriceWithTax;
    }
    return customizationpriceTotal;
  }
  


  /**to view recipt details  */
  receiptClick() {
    const element = document.querySelector('.main-div-wrap');
    if (element) { // Optional chaining
      element.scrollTo(0, 0);
      element.classList.add('Orderdet');
    }
    this.receiptMail = true;
    this.htmlData = this.sanitizer.bypassSecurityTrustHtml(this.orderInfoDetail.orderMailReceipt);
  }

  outerReceiptClick() {
    this.receiptMail = false;
    const element = document.querySelector('.main-div-wrap');
    if (element) { // Optional chaining
      element.classList.remove('Orderdet');
    }
  }

  downloadReceipt() {
    const container = document.getElementById('receiptContentDiv');
  
    if (container && 'querySelector' in container) { // type guard
      html2canvas(container).then(function (canvas) {
        // ... your code to create and download the image ...
        var link = document.createElement("a");
        document.body.appendChild(link);
        link.download = "oo_receipt.png";
        link.href = canvas.toDataURL("image/png");
        link.target = '_blank';
        link.click();
      });
    } else {
      // Handle the case where the element is not found
      console.error('Element with ID "receiptContentDiv" not found or has wrong type.');
    }
  }
  
  ngOnDestroy(): void {
    this.outerReceiptClick();
  }

  orderCustomizationInfo(itemDetail:any) {
    if (Array.isArray(itemDetail.customizations !== null) || Array.isArray(itemDetail.comboOptions !== null) || (itemDetail.remarks !== null && itemDetail.remarks.toString().trim().length > 0)) {
      itemDetail.isShow = !itemDetail.isShow;
    }else{
      itemDetail.isShow = !itemDetail.isShow;
    }
  
  }
  
orderAgainClick(){
    // this.utilService.progressService.isLoading = true;
    let orderid = this.orderId;
    this.orderHistoryService.getRecentOrderByOrderId(orderid).subscribe({
      next:(res:any) => {
        this.recentOrderObject = JSON.parse(res.object);/**json string return type convert to  object*/
        if(!this.validateRecentOrderShop()){
          return;
        }
         /**
          * GTM Code
          */
        //  this.gtmService.pushEvent(
        //   'order_again',
        //   {
        //     userId: this.gtmService.getCustomer(),
        //     previousStore: this.orderInfoDetail.shopName,
        //     previousDate: this.orderInfoDetail.deliveryDate,
        //     previousTime: this.orderInfoDetail.deliveryTime
        //   });
        this.cartService.setCartFromRecentOrders(this.recentOrderObject);

       },
       error:(error:any)=>{}
       });
  }

   /**
   * validate the shop of reordered item shop.
   * @returns 
   */
   validateRecentOrderShop() {
    let shopExist =true;
    let shopList = JSON.parse(this.storageService.loadFromSession(SESSION_SHOP_LIST));
    const selectedShop = shopList.filter((shp:any) => shp.id === this.recentOrderObject.shopId);
    if(selectedShop.length ===0){
      const message='You can not place this order again. The shop '+this.orderInfoDetail.shopName+' is not valid now.';
      var dlgData:NotificationDialogModel={
        message:message,
        title:"Sorry!",
        yesButtonCaption:"Ok",
      }
      this.messageDialogService.shoNotificationDialog(dlgData).subscribe(result => {
      });
      shopExist = false;
    }
    // this.utilService.progressService.isLoading = false;
    return shopExist;
  }


  scheduleDialog(buzType:any,shopID:any) {
    const shop = this.shopList.find((s: any) => s.id == shopID);
    const dlgData={businessType:buzType,shop:shop,redirectUrl:'shopDetail/:id',msg:this.msg}
    this.dialog.open(OrderScheduleComponent, {
      panelClass: 'schedule-dialog',
      data:dlgData
    });
  }

  isBentoShop(shopId: Number):boolean{
    const shop = this.shopList.find((s: any) => s.id == shopId);
    const shopDepartmentList=shop.department;
    const bentoDpt=this.departmentList?.find((dpt: any) => dpt.code =='BENTO');
    const shopBentodpt=shopDepartmentList?.find((dpt: any) => dpt ==bentoDpt.id);
    return (shopBentodpt!==undefined && shopBentodpt!==null);
  }
}
