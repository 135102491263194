import { Component, HostListener } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { LoginComponent } from '../login/login.component';
import { CommonModule } from '@angular/common';
import { StorageService } from '../../services/storage/storage.service';
import { CustomerService } from '../../services/customer/customer.service';
import { CartService } from '../../services/cart/cart.service';
import { NotificationService } from '../../services/shared/notifications.service';
import { RewardsComponent } from '../rewards/rewards.component';
import { SESSION_AMENITY_LIST, SESSION_BUSINESS_TYPE_LIST, SESSION_CART, SESSION_CUSTOMER_ACTIVE_REWARDS, SESSION_ITEM_CATEGORY_LIST, SESSION_ONLINE_SERVICE_LIST } from '../../app.constants';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { OOModule } from '../../modules/oo.module';
import { Cart } from '../../models/cart.model';
import { ConfirmDialogModel, MessageDialogService, NotificationDialogModel } from '../../services/shared/message-dialog.service';
import { MessageDialogComponent } from './components/message-dialog/message-dialog.component';
import { animate, keyframes, state, style, transition, trigger } from '@angular/animations';
import { LoginService } from '../login/login.service';
import { SignupComponent } from '../signup/signup.component';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [CommonModule, OOModule, RouterModule],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
  animations: [
    trigger('shake', [
      state('still', style({ transform: 'translate(0)' })),
      state('shaking', style({ transform: 'translate(0)' })),  // Base style for shaking
      transition('still => shaking', [
        animate('500ms', keyframes([
          style({ transform: 'translate(-3px, -3px)', offset: 0.1 }),
          style({ transform: 'translate(3px, 3px)', offset: 0.2 }),
          style({ transform: 'translate(-3px, -3px)', offset: 0.3 }),
          style({ transform: 'translate(3px, 3px)', offset: 0.4 }),
          style({ transform: 'translate(-3px, -3px)', offset: 0.5 }),
          style({ transform: 'translate(3px, 3px)', offset: 0.6 }),
          style({ transform: 'translate(-3px, -3px)', offset: 0.7 }),
          style({ transform: 'translate(3px, 3px)', offset: 0.8 }),
          style({ transform: 'translate(0)', offset: 0.9 }), // Return to center
          style({ transform: 'translate(0)', offset: 1 }) // Return to center
        ]))
      ]),
      transition('shaking => still', [
        animate('100ms', style({ transform: 'translate(0)' }))
      ])
    ]),]

})
export class HeaderComponent {
  title = 'St Pierres Online';
  responsive: any = false;
  innerWidth: any;
  isVisibile: any;
  loginStatus: boolean = false;
  customer: any;
  rewardsData: any;
  rewardIconVisible: boolean = false;
  amenities: any;
  businessTypeList: any;
  onlineServices: any;
  itemList: any;
  filtereditemList: any;
  cart: Cart;
  cartItemInfo: any = [];
  lastOrderShopName: any;
  lastOrderShopId: any;
  recentOrderList: any;
  animationState = 'still';  // Initial state
  sourceParam: any;
  loginParam: any;
  registerParam: any;

  constructor(public dialog: MatDialog,
    private route: ActivatedRoute,
    private storageService: StorageService,
    private customerService: CustomerService,
    public cartService: CartService,
    private router: Router,
    private notificationService: NotificationService,
    private messageDialogService: MessageDialogService,
    private loginService: LoginService) {
    this.sourceParam = this.route.snapshot.queryParams['source'];
    this.loginParam = this.route.snapshot.queryParams['login'];
    this.registerParam = this.route.snapshot.queryParams['register'];
    this.cart = this.cartService.cart;
    this.cartItemInfo = this.cart.items
    this.notificationService.rewardsLoaded.subscribe(reward => {
      this.rewardsData = reward;
      this.rewardIconVisible = (this.rewardsData !== null &&
        this.rewardsData !== undefined && this.rewardsData.length > 0);
    });
    this.notificationService.cartChanged.subscribe(param => {
      this.animationState = "shaking";
      setTimeout(() => {
        this.animationState = "still";
      }, 1000);

    });

    this.notificationService.userLoginChanged.subscribe(status => {
      this.setMenuOptions();
    });


  }

  /* When click the LOGIN the popUp will display */
  loginDialog() {
    const dialogRef = this.dialog.open(LoginComponent, {
      panelClass: 'login-dialog'
    });
    dialogRef.afterClosed().subscribe((data) => {
    });
  }

  signupDialog() {
    const dialogRef = this.dialog.open(SignupComponent, {
      panelClass: 'signup-dialog'
    })
    dialogRef.afterClosed().subscribe((status) => {
      if(status){
        this.loginDialog();
      }
    });
  }

  rewardDialog() {
    this.dialog.open(RewardsComponent, {
      panelClass: 'reward-dialog'
    });
  }

  navigateToItemList() {
    const cart = this.cartService.getCustomerCart();
    const shop = this.customerService.getShopDetails();
    if ((shop == null && shop == undefined) && (cart.customer == null && cart.customer == undefined)) {
      var dlgData: NotificationDialogModel = {
        message: `No shop has been chosen. To proceed, kindly select a shop first.`,
        title: `NO SHOP SELECTED!!`,
        yesButtonCaption: "Ok",
      }
      this.messageDialogService.shoNotificationDialog(dlgData).subscribe(result => {
        if (result == 'Ok') {
          this.router.navigate(['/shopList'], { queryParams: { 'redirectUrl': "shopItem" } });
        }
      });
    }
    // else if((shop !== null && shop !== undefined) && (cart.customer == null && cart.customer == undefined)){
    //   alert('Please select')
    //   var dlgData: NotificationDialogModel = {
    //     message: `No shop has been chosen. To proceed, kindly select a shop first.`,
    //     title: `NO SHOP SELECTED!!`,
    //     yesButtonCaption: "Ok",
    //   }
    //   this.messageDialogService.shoNotificationDialog(dlgData).subscribe(result => {
    //     if (result == 'Ok') {
    //       this.router.navigate(['/shopList'], { queryParams: { 'redirectUrl': "shopItem" } });
    //     }
    //   });
    // }
    else if ((shop == null && shop == undefined) && (cart.customer !== null && cart.customer !== undefined)) {
      this.recentOrderList = JSON.parse(cart.customer.recentOrder);
      if (this.recentOrderList !== null && this.recentOrderList !== undefined) {
        this.lastOrderShopName = this.recentOrderList[0].shopName;
        this.lastOrderShopId = this.recentOrderList[0].shopId;
        const dlgData = { shopName: this.lastOrderShopName, shopId: this.lastOrderShopId, redirectUrl: '/shopItem' }
        this.dialog.open(MessageDialogComponent, {
          panelClass: 'app-message-dialog',
          data: dlgData
        });
      } else {
        // this.router.navigate(['/shopList']);
        var dlgData: NotificationDialogModel = {
          message: `No shop has been chosen. To proceed, kindly select a shop first.`,
          title: `NO SHOP SELECTED!!`,
          yesButtonCaption: "Ok",
        }
        this.messageDialogService.shoNotificationDialog(dlgData).subscribe(result => {
          if (result == 'Ok') {
            this.router.navigate(['/shopList'], { queryParams: { 'redirectUrl': "shopItem" } });
          }
        });
      }
    }
    else {
      this.router.navigate(['/shopItem']);
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth <= 992) {
      this.responsive = true;
    }
    else {
      this.responsive = false;
    }
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.innerWidth = window.innerWidth;
    if (this.innerWidth <= 992) {
      this.responsive = true;
    }
    else {
      this.responsive = false;
    }
    this.setMenuOptions();
    this.autoLoginCheck();

    this.route.queryParams.subscribe(params => {
      if (params['source'] === 'welcome') {
        if (params['login'] === 'true') {
          this.loginDialog();
        }
        if (params['register'] === 'true') {
        this.router.navigate(['/registraction']);
        }
      }
    });
  }


  /*
   * When the user click Remember Me checkbox automatically login to the application
   */
  autoLoginCheck() {
    const localStorageCustomerId = this.storageService.loadFromLocalStorage()
    if (localStorageCustomerId != null &&
      localStorageCustomerId != undefined) {
      this.customerService.restoreCustomer(localStorageCustomerId).subscribe({
        next: (data: any) => {
          this.cartService.setCustomer(data.object.customer);
          this.notificationService.notifyLoginChange('in');
        },
        error: (err: any) => {
          this.notificationService.notifyLoginChange('out');
        }
      })
    }
  }

  /* When LOGIN The following options will display in the MENUBAR*/
  setMenuOptions() {
    const cart = JSON.parse(this.storageService.loadFromSession(SESSION_CART));
    this.customer = (cart !== null && cart !== undefined) ? cart.customer : undefined;
    this.loginStatus = (this.customer !== null &&
      this.customer !== undefined && this.customer.customerType !== 2);
    // this.rewardsData = JSON.parse(this.storageService.loadFromSession(SESSION_CUSTOMER_ACTIVE_REWARDS));
    this.rewardsData = this.storageService.loadFromSession(SESSION_CUSTOMER_ACTIVE_REWARDS);
    this.rewardsData = (this.rewardsData !== null && this.rewardsData != undefined && this.rewardsData !== "") ? JSON.parse(this.rewardsData) : [];
    this.rewardIconVisible = (this.rewardsData !== null &&
      this.rewardsData !== undefined && this.rewardsData.length > 0);
  }

  /* When LOGOUT the session and Local storages are cleared*/
  logout() {
    this.dialog.closeAll();
    this.loginService.logout();
  }

  cartNavigate() {
    if (this.cart.items?.length !== 0) {
      if (this.cart.shop == null && this.cart.shop == undefined) {
        if (this.cart.customer == null && this.cart.customer == undefined) {
          this.noShopSelectionMessage();
        } else {
          this.recentOrderList = JSON.parse(this.cart.customer.recentOrder);
          if (this.recentOrderList !== null && this.recentOrderList !== undefined) {
            this.lastOrderShopName = this.recentOrderList[0].shopName;
            this.lastOrderShopId = this.recentOrderList[0].shopId;
            const dlgData = { shopName: this.lastOrderShopName, shopId: this.lastOrderShopId, redirectUrl: "shoppingCart" }
            this.dialog.open(MessageDialogComponent, {
              panelClass: 'app-message-dialog',
              data: dlgData
            });
          } else {
            this.noShopSelectionMessage();
          }
          // var dlogData: ConfirmDialogModel = {
          //   message: `We noticed you haven't chosen a shop yet, 
          //   but based on your previous order, we've selected <strong>${this.lastOrderShopName}</strong> shop for you.
          //   If this is the shop you want, simply click Continue. Otherwise, click Select Shop to choose a different one.`,
          //   title: "Shop selection",
          //   noButtonCaption:"Select Shop",
          //   yesButtonCaption:"Continue"
          // }

          // var res = this.messageDialogService.showConfirmDialog(dlogData).subscribe(result => {
          //   if (result == "Continue") {
          //     this.router.navigate(['shopDetail/'+this.lastOrderShopId]);
          //   }
          //   else{
          //     this.router.navigate(['/shopList'],{ queryParams: {'src':"home"} });
          //   }
          // });
        }
      } else {
        this.router.navigate(["/shoppingCart"]);
      }
    }
    else {
      this.dialog.open(CartConfirmation, {
        panelClass: 'confirm-dialog'
      })
    }
  }

  noShopSelectionMessage() {
    var dlgData: NotificationDialogModel = {
      message: `No shop has been chosen. To proceed, kindly select a shop first.`,
      title: `NO SHOP SELECTED!`,
      yesButtonCaption: "Ok",
    }
    this.messageDialogService.shoNotificationDialog(dlgData).subscribe(result => {
      if (result == 'Ok') {
        this.router.navigate(['/shopList'], { queryParams: { 'redirectUrl': "shoppingCart" } });
      }
    });
  }

  /** navigate back to home page  */
  HomePage() {
    this.router.navigate(['/']);
  }
}

@Component({
  selector: 'delete-confirmation',
  template: `
  <section>
    <h5 class="font-secondary mb-4">Confirmation</h5>
    <p>Do you want to delete Small Teriyaki Chicken on Rice with Mayo?</p>
    <div class="text-end pt-1 font-secondary">
      <button class="btn btn-outline-dark py-2 px-4 rounded-pill me-2" (click)="close()">No</button>
      <button class="btn btn-primary py-2 px-4 rounded-pill" (click)="closeData()">Yes</button>
    </div>
  </section>`,
})

export class DeleteConfirmation {
  constructor(public dialogRef: MatDialogRef<DeleteConfirmation>) { }
  closeData() {
    this.dialogRef.close(true)
  }
  close() {
    this.dialogRef.close(false)
  }
}

@Component({
  selector: 'cart-empty',
  template: `
  <section>
    <h5 class="font-secondary mb-4">Your cart is empty</h5>
    <p>
It appears that your cart is currently empty. Feel free to explore our top items and add anything that catches your interest.</p>
    <div class="text-end pt-1 font-secondary">
      <button class="btn btn-outline-dark py-2 px-4 rounded-pill me-2" (click)="close()">Ok</button>
    </div>
  </section>`,
  styles: [`

  
  ::ng-deep{
    .mdc-dialog__surface{ 
        flex-shrink: revert;
        
   }
   
}



  `]
})
export class CartConfirmation {
  constructor(public dialogRef: MatDialogRef<DeleteConfirmation>) { }
  close() {
    this.dialogRef.close(false)
  }
}
