<div class="back-strip bg-white">
    <div class="container py-3 font-secondary">
      <button
        class="btn btn-outline-dark rounded-pill py-2 px-3"
        (click)="back()"
      >
        <img
          src="assets/icons/back_arrow_icon.svg"
          class="me-lg-2 white-icon"
          alt=""
        />
        <span class="align-middle d-none d-lg-inline">BACK</span>
      </button>
      <p
        class="d-inline-block ms-lg-4 mb-0 fs-5 page-heading text-center align-middle"
      >
        Create an account
      </p>
    </div>
  </div>
<div class="container">
    <button
  class="btn btn-close shadow-none opacity-100 position-absolute top-0 end-0 m-4"
  
></button>
<div class="mb-4 pb-2 pt-2">
  <h3 class="font-secondary mb-1"></h3>
  <p class="mb-2">Let’s get started by filling out information below</p>
</div>

<form
  class="overflow-y-auto overflow-x-hidden theme-scrollbar"
  [formGroup]="singUpForm"
  mat-content
>
  <div class="mb-4 pb-1">
    <label for="loginId" class="form-label mb-2">Email*</label>
    <input
      type="email"
      class="form-control shadow-none rounded-1 py-2 px-3"
      id="loginId"
      formControlName="email"
    />
    @if (singUpForm.controls['email'].hasError('required')) {
    <div class="form-field-error">Please enter email.</div>
    } @if (singUpForm.controls['email'].hasError('email')) {
    <div class="form-field-error">Please enter valid email.</div>
    }
  </div>

  <div class="row">
    <div class="col-md-6">
      <div class="mb-4 pb-1">
        <label for="firstName" class="form-label mb-2">First Name*</label>
        <input
          type="text"
          class="form-control shadow-none rounded-1 py-2 px-3"
          id="firstName"
          formControlName="firstName"
        />
        @if (singUpForm.controls['firstName'].hasError('required')) {
        <div class="form-field-error">Please enter first name.</div>
        }
      </div>
    </div>
    <div class="col-md-6">
      <div class="mb-4 pb-1">
        <label for="lastName" class="form-label mb-2">Last Name</label>
        <input
          type="text"
          class="form-control shadow-none rounded-1 py-2 px-3"
          id="lastName"
          formControlName="lastName"
        />
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6">
      <div class=" mb-4 pb-1">
        <div class="d-flex">
          <label for="password" class="form-label mb-2">Password*</label>
          @if((!passwordFormField?.value?.match('^(?=.*[A-Z])'))||(!passwordFormField?.value?.match('(?=.*[a-z])'))
          ||(!passwordFormField?.value?.match('(.*[0-9].*)'))||(!passwordFormField?.value?.match('(?=.*[!@#$%^&*])'))
          ||(!passwordFormField?.value?.match('.{8,}'))){
          <div style="margin-left: auto;">
            <i class="fa fa-question-circle" aria-hidden="true" style="color:#ba1b1b;font-size: 15px;"></i>&nbsp;
            <span style="color:#ba1b1b;font-size: 15px;text-transform: lowercase;">Weak</span>
          </div>
          }
          @if((passwordFormField?.value?.match('^(?=.*[A-Z])'))&&(passwordFormField?.value?.match('(?=.*[a-z])'))
          &&(passwordFormField?.value?.match('(.*[0-9].*)'))&&(passwordFormField?.value?.match('(?=.*[!@#$%^&*])'))
          &&(passwordFormField?.value?.match('.{8,}'))){
            <div style="margin-left: auto;">
              <i class="fa fa-solid fa-circle-check" aria-hidden="true" style="color:#3E8635;font-size: 15px;"></i>&nbsp;
              <span style="color:#3E8635;font-size: 15px;text-transform: lowercase;">Strong</span>
            </div>
          }
        </div>
        <div class="position-relative">
          <input [type]="pwHide ? 'password' : 'text'" class="form-control shadow-none rounded-1 py-2 px-3"
            id="password" formControlName="newPassword" (input)="onPasswordChange('new')" />


          @if (singUpForm.controls['newPassword'].hasError('required')) {
          <div class="form-field-error">Please enter new password.</div>
          }

          @else if(passwordFormField?.dirty){
            @if (!passwordFormField?.value?.match('^(?=.*[A-Z])')) {
              <div class="form-field-error">Add at least one uppercase letter (A-Z)
              </div>
            }
            @else if (!passwordFormField?.value?.match('(?=.*[a-z])')) {
              <div class="form-field-error">Add at least one lowercase letter (a-z)
              </div>
            }
            @else if (!passwordFormField?.value?.match('(.*[0-9].*)')) {
              <div class="form-field-error">Add at least one number (0-9)
              </div>
            }
            @else if (!passwordFormField?.value?.match('(?=.*[!@#$%^&*])')) {
              <div class="form-field-error">Add at least one special character (!&#64;#$%^&*)</div>
            }
            @else if(!passwordFormField?.value?.match('.{8,}')){
              <div class="form-field-error">Be at least 8 characters long.</div>
            }
            @else {
              
            }
          }
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="pb-1">
        <label for="confirmPw" class="form-label mb-2">Confirm Password*</label>
        <div class="position-relative">
          <input [type]="cwHide ? 'password' : 'text'" class="form-control shadow-none rounded-1 py-2 px-3"
            id="confirmPw" formControlName="confirmPassword" (input)="onPasswordChange('confirm')" />

          @if (singUpForm.controls['confirmPassword'].hasError('required')) {
          <div class="form-field-error">Please enter comfirm password.</div>
          } @if(confirmPassError!=='') {
          <div class="form-field-error">{{ confirmPassError }}</div>
          }
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6">
      <div class="mb-4 pb-1">
        <label for="phone" class="form-label mb-2">Phone Number*</label>
        <input
          type="text"
          class="form-control shadow-none rounded-1 py-2 px-3"
          id="phone"
          formControlName="phoneNumber"
        />
        @if (singUpForm.controls['phoneNumber'].hasError('required')) {
        <div class="form-field-error">Please enter phone number.</div>
        } @if (singUpForm.controls['phoneNumber'].hasError('minlength')) {
        <div class="form-field-error">Please enter valid phone number.</div>
        }
      </div>
    </div>
    <div class="col-md-6">
      <div class="mb-4 pb-2">
        <label for="dob" class="form-label mb-1">DOB</label>
        <div class="position-relative">
          <div
            style="display: flex; flex-direction: row"
            id="dateOfBirth"
            class="date-picker py-1"
          >
            <mat-select
              name="day"
              id="day"
              style="
                width: 30%;
                margin-right: 5px;
                text-align: center;
                height: 45px;
                outline: none;
                font-size: 16px;
                border-radius: 5px;
                padding-left: 15px;
                padding-top: 6px;
                border: 1px solid #ccc;
                border-bottom-width: 2px;
                transition: all 0.3s e;
              "
              panelClass="rounded"
              placeholder="DD"
            >
              @for(day of days; track $index) {
              <mat-option value="day.value">{{ day.label }}</mat-option>
              }
            </mat-select>

            <mat-select
              name="month"
              id="month"
              style="
                width: 30%;
                margin-right: 5px;
                text-align: center;
                height: 45px;
                outline: none;
                font-size: 16px;
                border-radius: 5px;
                padding-left: 15px;
                padding-top: 6px;
                border: 1px solid #ccc;
                border-bottom-width: 2px;
                transition: all 0.3s e;
              "
              panelClass="rounded"
              placeholder="MMM"
            >
              @for(month of months; track $index) {
              <mat-option value="month.value">{{ month.label }}</mat-option>
              }
            </mat-select>

            <mat-select
              name="year"
              id="year"
              style="
                width: 30%;
                margin-right: 5px;
                text-align: center;
                height: 45px;
                outline: none;
                font-size: 16px;
                border-radius: 5px;
                padding-left: 15px;
                padding-top: 6px;
                border: 1px solid #ccc;
                border-bottom-width: 2px;
                transition: all 0.3s e;
              "
              panelClass="rounded"
              placeholder="YYYY"
            >
              @for (year of pastYears; track $index) {
              <mat-option value="year.value">{{ year.label }}</mat-option>
              }
            </mat-select>
          </div>
          
        </div>
      </div>
    </div>
  </div>
  <div class="form-check mb-3 pb-1">
    <input
      role="button"
      class="form-check-input shadow-none mt-0"
      type="checkbox"
      value=""
      id="news"
      formControlName="subscription"
    />
    <label role="button" class="form-check-label ms-2 align-middle" for="news">
      Please send me information on discounts, promotion and other news.
    </label>
  </div>
  <div class="form-check mb-3 pb-1">
    <input
      role="button"
      class="form-check-input shadow-none mt-0"
      type="checkbox"
      value=""
      id="policy"
      formControlName="agree"
    />
    <label
      role="button"
      class="form-check-label ms-2 align-middle"
      for="policy"
    >
      I agree with St Pierres Shushi’s <span class="terms-link" role="button" (click)="termsConditionsDialog()">Terms & Conditions and Policies
      </span>
    </label>
  </div>
  <div class="mb-2">
    
    
  </div>
</form>


<div class="mt-3 mb-2">

  @if(registrationStatus==0){
    <button
    class="btn btn-primary py-2 px-4 font-secondary rounded-pill "
    type="submit"
    (click)="signUp()"
    
  >
    Sign Up
  </button>
  }
  @if(registrationStatus==1){
    <button
    class="btn btn-primary py-2 px-4 font-secondary rounded-pill text-dark"
    type="submit"
    disabled
    
  >
  registering&nbsp;<span class="spinner-border text-dark" style="height: 18px;width: 18px;"></span>
  </button>
  }

  
</div>

</div>
