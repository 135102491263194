import { Component, ViewChild } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CarouselModule } from 'ngx-owl-carousel-o';

import { MatAutocomplete, MatAutocompleteModule, MatAutocompleteTrigger } from '@angular/material/autocomplete';

import { FormControl } from '@angular/forms';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Observable, startWith, map } from 'rxjs';
import { OOModule } from '../../modules/oo.module';
import { environment } from '../../environments/environment';
import { HomeService } from './home.service';
import { StorageService } from '../../services/storage/storage.service';
import { RewardService } from '../../services/shared/reward.service';
import { Router, RouterModule } from '@angular/router';
import { NotificationService } from '../../services/shared/notifications.service';
import { SESSION_CART, SESSION_CUSTOMER_ACTIVE_REWARDS, SESSION_NEAREST_SHOP_LIST, SESSION_SHOP_LIST, SESSION_DEPARTMENT_LIST, SESSION_AMENITY_LIST, SESSION_SYSTEM_SETTINGS, SESSION_PICKUP_LOCATION_LIST, SESSION_OFFERS, SESSION_FILTERED_OFFERS, SESSION_SALE_ITEM_LIST, SESSION_START_TIME, SESSION_AUTHENTICATED_CUSTOMER, SESSION_ONLINE_SERVICE_LIST, SESSION_BUSINESS_TYPE_LIST, SESSION_ITEM_CATEGORY_LIST, SESSION_APPLIED_REWARDS, SESSION_SELECTED_SHOP, SESSION_CUSTOMER_REWARDS, SESSION_COMPANY_INFO, PROMOTION_ELIGIBILITY_TYPE_OFFER, SESSION_MASTER_CUSTOMIZATION, SESSION_MASTER_COMBO_CUSTOMIZATION, SESSION_SHOW_NOTICE } from '../../app.constants';
import { CartService } from '../../services/cart/cart.service';
import { ItemListService } from '../../services/shared/item-list.service';
import { SettingsService } from '../../services/shared/settings.service';
import { UtilService } from '../../services/shared/util.service';
import { PromotionCardComponent } from './components/promotion-card/promotion-card.component';
import { CustomerService } from '../../services/customer/customer.service';
import { CommonModule } from '@angular/common';
import { OrderScheduleComponent } from '../order-schedule/order-schedule.component';
import { MatDialog} from '@angular/material/dialog';
import { GtmService } from '../../services/shared/gtm.service';
import { SplashComponent } from '../splash/splash.component';
import { SplashService } from '../splash/splash.service';
import { ShownoticeComponent } from "../shownotice/shownotice.component";
import { DomSanitizer } from '@angular/platform-browser';
import moment from 'moment';


@Component({
  selector: 'app-home',
  standalone: true,
  imports: [OOModule,
    CarouselModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    RouterModule,
    PromotionCardComponent,
    CommonModule,
    SplashComponent, ShownoticeComponent],
  providers: [],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss'
})
export class HomeComponent {
  recentOrderList: any;
  lastOrderShopName: any;
  customerName: any;
  rewardsData: any;
  offersData: any;
  isRegionListOpen: any;
  showError = false;
  locationCoordinateData: any;
  selectedLocation: any;
  RegionCurrentLocationId = 100000;
  radius: any;
  selectedShopList: any = [];
  isCurrentLocationAvilable = false;
  nearestShopList: any = [];
  nearestShopArray: any;
  shopList: any = [];
  departmentList: any = [];
  amenities: any;
  crmAssetsUrl = environment.crm.assetsUrl;
  oosAssetsUrl = environment.oos.assetsUrl;
  searchControl = new FormControl('');
  filteredShopList: any = [];
  customizationOptionValues: any;
  systemSettings: any;
  currencySymbol: any;
  lastOrderShopId: any;
  saleItemList: any;
  businessTypeList: any;
  itemCategoryList: any;
  promotion: any;
  appliedRewards: any = [];
  defaultShop: any;
  cart: any;
  selectedShop: any;
  deliveryDate: any;
  deliveryTime: any;
  deliveryMethod: any;
  deliveryDateTime: any;
  buildNo: any;
  showBuildNo: boolean;
  customerInfo: any;
  companyInfo: any = [];
  @ViewChild('homeSearch') homeSearch!: MatAutocompleteTrigger;
  isOrderScheduleTrgd: boolean = false;
  bentoDpt: any;
  isRewardsAvailable: boolean = false;
  customizationComboOptionValues: any;
  showSplash: boolean;
  intervalId: any;
  visibility: any;


  constructor(private homeService: HomeService,
    private storageService: StorageService,
    private rewardService: RewardService,
    private router: Router,
    private notificationService: NotificationService,
    private cartService: CartService,
    private itemListService: ItemListService,
    private settingsService: SettingsService,
    private itemService: ItemListService,
    private utilService: UtilService,
    private customerService: CustomerService,
    public dialog: MatDialog,
    private gtmService:GtmService,
    private splashService:SplashService,
    private sanitizer: DomSanitizer) {

    this.buildNo = environment.buildNo;
    this.showBuildNo = false;
    this.showSplash=this.showSplash = this.splashService.show();
    if (this.showSplash) {
      this.intervalId = setInterval(() => {
        this.showSplash = this.splashService.show();
        if (!this.showSplash) {
          clearInterval(this.intervalId);
        }
      }, 1000);
    }
  }

  /**
   * Show the banner according to the date
   * @returns 
   */
  showTeaser():boolean{
    var dateFrom=moment(new Date("2024-09-24"),"yyyy-MM-dd");
    var dateTo=moment(new Date("2024-09-30"),"yyyy-MM-dd");
    var currentDate=moment(new Date(),"yyyy-MM-dd");
    var show=(currentDate.isBetween(dateFrom, dateTo,'day','[]'));
    return show;
  }

  showGiveAway():boolean{
    var dateFrom=moment(new Date("2024-10-01"),"yyyy-MM-dd");
    var dateTo=moment(new Date("2024-10-01"),"yyyy-MM-dd");
    var currentDate=moment(new Date(),"yyyy-MM-dd");
    var show=(currentDate.isBetween(dateFrom, dateTo,'day','[]'));
    return show;
  }
  
  /***Options slides defined for Rewards***/
  homeSlides: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    navSpeed: 700,
    autoWidth: false,
    responsive: {
      0: {
        items: 1,
        margin: 12,
      },
      552: {
        items: 1,
        margin: 12
      },
      740: {
        items: 2,
        margin: 12
      },
      968: {
        items: 3,
        margin: 12
      },
      1040: {
        items: 10,
        margin: 30,

      }
    },
    nav: false
  }

  /***Options slides defined for Special offers***/
  orderSlides: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    navSpeed: 700,
    navText: ['<', '>'],
    responsive: {
      0: {
        items: 1,
        margin: 10
      },
      552: {
        items: 2,
        margin: 10
      },
      740: {
        items: 3,
        margin: 10
      },
      968: {
        items: 3,
        margin: 10,
      },
      1040: {
        items: 3,
        margin: 30
      }
    },
    nav: false
  }

  ngOnInit() {
    this.utilService.scrollToTop();
    this.setMasterData();
    this.searchControl.valueChanges.subscribe(searchTerm => {
      this.filterShops();
    });

    this.appliedRewards = JSON.parse(this.storageService.loadFromSession(SESSION_APPLIED_REWARDS));
    if (this.appliedRewards == undefined) {
      this.appliedRewards = [];
    }
    this.notificationService.recentOrders.subscribe(recentOrder => {
      this.recentOrderList = recentOrder;
      this.lastOrderShopName = this.recentOrderList[0].shopName;
      this.lastOrderShopId = this.recentOrderList[0].shopId;
    });

    this.notificationService.userLoginChanged.subscribe(status => {
      if (status == 'in') {
        this.customerInfo = this.cartService.cart.customer;
        this.customerName = this.customerInfo?.firstName;
        this.storageService.saveToSession(SESSION_AUTHENTICATED_CUSTOMER, JSON.stringify(this.customerInfo));
        this.recentOrders();
        this.setRewards();
      }
      else {
        this.recentOrderList = undefined;
        this.rewardsData = undefined;
        this.customerName = undefined;
        this.lastOrderShopName = undefined;
        this.lastOrderShopId = undefined;
      }
    });
  }

  /**
   * SET all Master Data into the session
   */
  setMasterData() {
    this.setCompanyInfo();
    this.setCustomerInfo();
    this.setAllShop();
    this.setAllSaleItem();
    this.setAllDepartments();
    this.setCustomizationOptionDtl();
    this.setSystemSettings();
    this.setPickUpLoacations();
    this.filterShops();
    this.recentOrders();
    this.setOffers();
    this.setRewards();
    this.setAmenities();
    this.setOnlineServices();
    this.setBusinessType();
    this.setItemCategoryList();
    this.setDefaultShop();
    const now = new Date();
    this.storageService.saveToSession(SESSION_START_TIME, now)
  }

  /*** When the user is logged IN Pick the details of the user ***/
  setCustomerInfo() {
    this.customerInfo = this.cartService.cart.customer;
    this.customerName = this.customerInfo?.firstName;
  }

  /*** When the user is logged IN Pick the recent orders of the user ***/
  recentOrders() {
    if (this.cartService.cart.customer !== undefined && this.cartService.cart.customer.customerType !== 2) {
      this.cartService.getRecentOrder(this.cartService.cart.customer?.id).subscribe((recentOrderData: any) => {
        // this.cartService.setRecentOrderCustomerToCart(recentOrderData.object.recentOrder);
        this.recentOrderList = JSON.parse(recentOrderData.object.recentOrder);
        if(this.recentOrderList !== null && this.recentOrderList!==undefined && this.recentOrderList.length != 0){
        this.lastOrderShopName = this.recentOrderList[0].shopName;
        this.lastOrderShopId = this.recentOrderList[0].shopId;
        this.notificationService.notifyRecentOrder(this.recentOrderList);
        }
      });
    }
    // const cart = this.cartService.cart;
    // if ((cart != null && cart != undefined) && (cart.customer != null && cart.customer != undefined)) {
    //   if (cart.customer.recentOrder !== null && cart.customer.recentOrder !== undefined) {
    //     this.recentOrderList = JSON.parse(cart.customer.recentOrder);
    //     this.lastOrderShopName = this.recentOrderList[0].shopName;
    //   this.lastOrderShopId = this.recentOrderList[0].shopId;
    //     this.notificationService.notifyRecentOrder(this.recentOrderList);
    //   }
    // }
  }

  /** SET All pickup locations**/
  setPickUpLoacations() {
    this.homeService.getPickUpLocations().subscribe((locations: any) => {
      const pickUplocations = JSON.stringify(locations.object);
      this.storageService.saveToSession(SESSION_PICKUP_LOCATION_LIST, pickUplocations);
    });
  }

  /** SET All Shops**/
  setAllShop() {
    this.homeService.getShops().subscribe((shopdata: any) => {
      this.shopList = shopdata.object;
      // this.filteredShopList = this.shopList;
      this.filteredShopList = this.shopList.sort(function (a: any, b: any) {
        if (a.name < b.name) return -1; // Sort ascending (a before b)
        if (a.name > b.name) return 1; // Sort ascending (b before a)
        return 0; // Equal names
      });
      this.storageService.saveToSession(SESSION_SHOP_LIST, JSON.stringify(this.shopList));

    });
  }

  /**
   * set the default shop of user
   */
  setDefaultShop() {
    this.cart = this.cartService.cart;
    if (this.cart.shop !== null && this.cart.shop !== undefined) {
      this.defaultShop = this.cart.shop;
      this.selectedShop = this.cartService.getShop();
      this.deliveryDate = this.cartService.getDeliveryDate();
      this.deliveryTime = this.cartService.getDeliveryTime();
      this.deliveryMethod = this.cartService.getService();
      this.deliveryDateTime = this.deliveryDate.date + " " + this.deliveryTime;
    }
    else {
      this.setCustomerInfo();
    }
  }

  navigateToshopList() {
    this.router.navigate(['/shopList']);
  }

  /** SET All Sale ITEMS**/
  setAllSaleItem() {
    this.itemListService.loadSaleItem().subscribe((saleItem: any) => {
      this.saleItemList = saleItem.object;
      this.storageService.saveToSession(SESSION_SALE_ITEM_LIST, JSON.stringify(this.saleItemList));
    });
  }

  /***GET all online services and save into session */
  setOnlineServices() {
    this.customerService.getOnlineService().subscribe((service: any) => {
      const onlineServices = service.object;
      const pickupService = onlineServices.find((service: any) => service.code == 'PICKUP');
      this.cartService.setService(pickupService);
      this.storageService.saveToSession(SESSION_ONLINE_SERVICE_LIST, JSON.stringify(onlineServices));
    });
  }

  /***GET all amentities and save into session */
  setAmenities() {
    this.customerService.getAmenities().subscribe((amentites: any) => {
      this.amenities = JSON.stringify(amentites.object);
      this.storageService.saveToSession(SESSION_AMENITY_LIST, this.amenities);
    });
  }

  /***GET All business Types */
  setBusinessType() {
    this.customerService.getBusinessType().subscribe((businessType: any) => {
      this.businessTypeList = JSON.stringify(businessType.object);
      this.storageService.saveToSession(SESSION_BUSINESS_TYPE_LIST, this.businessTypeList);
    });
  }

  /***GET All Items Types */
  setItemCategoryList() {
    this.customerService.getItemList().subscribe((item: any) => {
      this.itemCategoryList = JSON.stringify(item.object);
      this.storageService.saveToSession(SESSION_ITEM_CATEGORY_LIST, this.itemCategoryList);
    });
  }
  /** SET All Departments**/
  setAllDepartments() {
    this.homeService.getDepartments().subscribe((departmentdata: any) => {
      this.storageService.saveToSession(SESSION_DEPARTMENT_LIST, JSON.stringify(departmentdata.object));
      this.departmentList = JSON.parse(this.storageService.loadFromSession(SESSION_DEPARTMENT_LIST));
      this.bentoDpt = this.departmentList?.find((dpt: any) => dpt.code == 'BENTO');
    });
  }

  /**
   * Determins the notice to show or not
   * @returns 
   */
  showNotice(): boolean {
    var result: boolean = false;
    const sessionData = this.storageService.loadFromSession(SESSION_SHOW_NOTICE);
    if (sessionData === null) {
      result = (this.companyInfo !== undefined && this.companyInfo.showInfoPage);
    }

    return result;
  }

  closeNotice(){

  }
  
  setCompanyInfo() {
    this.homeService.getCompanyInfo().subscribe((companydata: any) => {
      this.companyInfo = companydata.object[0];
      this.storageService.saveToSession(SESSION_COMPANY_INFO, JSON.stringify(this.companyInfo));

    });
  }

  /**** GET all offers for all customers****/
  setOffers() {
    const validOffers: any = [];
    const offerPayload = { "search": { "filters": { "simpleSearch": [], "advSearch": [], "scope": { "limit": "100", "offset": "0" } }, "sort": [] } }
    this.homeService.offers(offerPayload).subscribe((data: any) => {
      this.offersData = data.data.offers;
      // if(this.offersData !== undefined && this.offersData.length !== 0){
      //   this.offersData.forEach((offer:any) =>{
      //     const isValidOffer=this.rewardService.offerItemvalidation(offer);
      //     if(isValidOffer==true){
      //       validOffers.push(offer);
      //     }
      //   });
      // }
      this.storageService.saveToSession(SESSION_OFFERS, JSON.stringify(this.offersData));
      if (this.offersData !== undefined && this.offersData !== null) {
        var offers = this.offersData.filter((offer: any) => offer.reward_eligibility == 1)
      }

      this.storageService.saveToSession(SESSION_FILTERED_OFFERS, JSON.stringify(offers));
    });
  }

  /**
   * decided which offers to show when logged in and logged out
   */
  canShowOffers(offerData: any): boolean {
    var visible = false;
    if (offerData !== undefined && offerData.length > 0) {
      if (this.cartService.isGuestLogin() && offerData.some((offer: any) => offer.show_in_offers == 1)) {
        visible = true;
      }
      else if (offerData.some((offer: any) => offer.show_in_offers == 1 && offer.reward_eligibility == PROMOTION_ELIGIBILITY_TYPE_OFFER)) {
        visible = true;
      }
    }
    return visible;
  }

  setCustomizationOptionDtl() {
    //Set customization option in item
    this.itemListService.loadCustomizationOptionDtl().subscribe(
      res => {
        this.customizationOptionValues = JSON.parse(res.object);
        this.storageService.saveToSession(SESSION_MASTER_CUSTOMIZATION, JSON.stringify(this.customizationOptionValues));
      });
    //Set customization option values in combos
    this.itemListService.loadCustomizationComboOptionDtl().subscribe(
      res => {
        this.customizationComboOptionValues = JSON.parse(res.object);
        this.storageService.saveToSession(SESSION_MASTER_COMBO_CUSTOMIZATION, JSON.stringify(this.customizationComboOptionValues));
      });
  }

  setSystemSettings() {
    this.settingsService.loadSystemSettings().subscribe(
      res => {
        this.systemSettings = res.object;
        this.storageService.saveToSession(SESSION_SYSTEM_SETTINGS, JSON.stringify(this.systemSettings));
        this.currencySymbol = this.systemSettings.currency.symbol
      }
    );
  }
  /**** GET all Rewards for logined customer****/
  setRewards() {
    const cart = this.cartService.getCart();
    const customer = cart.customer;
    const validReward: any = [];
    if ((customer != null && customer != undefined && customer.customerType != 2)) {
      this.rewardService.loadCustomerRewards(customer.id).subscribe(response => {
        var reward = response.data.customer_reward;
        if (reward !== undefined && reward !== null) {
          //   var offers=this.rewardService.getSessionOfferList();
          this.storageService.saveToSession(SESSION_CUSTOMER_REWARDS, JSON.stringify(reward));
          // reward.forEach((reward:any) =>{
          //   if(offers.some((offer:any) => offer.id == reward.id)){
          //     validReward.push(reward);
          //   }
          //   });
          const activeRewards = reward.filter((cr: any) => cr.customer_status == 1);
          // const activeRewards = validReward.filter((cr:any) => cr.customer_status==1);
          this.rewardsData = activeRewards;
          if (this.rewardsData.length) {
            this.isRewardsAvailable = true;
          } else {
            this.isRewardsAvailable = false;
          }
          this.storageService.saveToSession(SESSION_CUSTOMER_ACTIVE_REWARDS, JSON.stringify(activeRewards));
          this.notificationService.notifyRewardsLoaded(this.rewardsData);
        } else {
          this.rewardsData = undefined;
          this.storageService.saveToSession(SESSION_CUSTOMER_REWARDS, []);
          this.storageService.saveToSession(SESSION_CUSTOMER_ACTIVE_REWARDS, []);
        }
      });
    }
  }

  // rewardAutoApply(reward:any){
  //   this.appliedRewards.forEach((rewd:any) => {
  //     if(rewd.id !== reward.id){
  //       this.appliedRewards.push(reward)
  //       this.storageService.saveToSession(SESSION_APPLIED_REWARDS, JSON.stringify(this.appliedRewards));
  //       this.notificationService.notifyRewardChange(this.appliedRewards);
  //     }
  //   });
  // }

  getCurrentLocation() {
    if (navigator) {
      navigator.geolocation.getCurrentPosition(
        pos => {
          const lng = +pos.coords.longitude;
          const lat = +pos.coords.latitude;
          this.locationCoordinateData = { 'longitude': lng, 'latitude': lat, 'radius': environment.oos.initialRad };
          this.getShopListByLocation(this.locationCoordinateData);
        },
        err => {
          if (this.selectedLocation.id === this.RegionCurrentLocationId) {
            this.selectedShopList = [];
          }
        },
        {
          enableHighAccuracy: true,
          timeout: 5000,
          maximumAge: 0
        }
      );
    }
  }

  /* Selecting shop list of nearest location*/
  getShopListByLocation(locationData: any) {
    this.isCurrentLocationAvilable = false;
    this.shopList = JSON.parse(this.storageService.loadFromSession(SESSION_SHOP_LIST));
    this.homeService.getNearestShops(locationData).subscribe(
      (res) => {
        this.nearestShopList = res.object;
        this.storageService.saveToSession(SESSION_NEAREST_SHOP_LIST, JSON.stringify(this.nearestShopList));
        this.router.navigate(['/shopList']);
      }
    );
  }

  filteredOptions!: Observable<string[]>;

  filterShops() {
    const searchTerm = this.searchControl.value?.toLocaleLowerCase();

    // Filter the shopList using JavaScript's filter() method
    this.filteredShopList = this.shopList.filter((shop: any) => {
      return shop.name.toLowerCase().includes(searchTerm);
    });
  }


  //For reordering the order Again
  orderDetails(orderId: any) {
    this.router.navigate(['/order-details'], { queryParams: { 'id': orderId, 'src': "reorder" } });
  }

  /**
   * For normal Item selection and add to the cart
   * @param buzType 
   * @param shop 
   */
  scheduleDialog(buzType: any, shop: any) {
    this.gtmService.pushEvent("click_and_collect", {
      selection: buzType,
      visitorType: this.gtmService.getVisitorType()
    })
    const dlgData = { businessType: buzType, shop: shop, redirectUrl: '/shopItem', msg: "" }
    this.dialog.open(OrderScheduleComponent, {
      panelClass: 'schedule-dialog',
      data: dlgData
    });
    this.isOrderScheduleTrgd = true;
    if (this.homeSearch !== undefined) {
      this.homeSearch.closePanel();
    }
  }


  // /**
  //  * For offer Item add to cart for this already item added and after that shop selection
  //  * @param buzType 
  //  * @param shop 
  //  */
  // navigateForOfferSelection(buzType: any, shop: any) {
  //   const dlgData = { businessType: buzType, shop: shop, redirectUrl: '/shoppingCart', msg: "" }
  //   this.dialog.open(OrderScheduleComponent, {
  //     panelClass: 'schedule-dialog',
  //     data: dlgData
  //   });
  // }

  isBentoShop(shop: any): boolean {
    // const shop = this.shopList.find((s: any) => s.id == shopId);
    const shopDepartmentList = shop.department;
    // const bentoDpt = this.departmentList?.find((dpt: any) => dpt.code == 'BENTO');
    const shopBentodpt = shopDepartmentList?.find((dpt: any) => dpt == this.bentoDpt.id);
    return (shopBentodpt !== undefined && shopBentodpt !== null);
  }

  navigateToShopDetail(shopId: any) {
    if (!this.isOrderScheduleTrgd) {
      this.router.navigate(['shopDetail/' + shopId]);
    }
    this.isOrderScheduleTrgd = false;
  }
}

