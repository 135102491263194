<!-- <div class="position-sticky back-strip bg-white">
  <div class="container py-3 font-secondary">
    <button class="btn btn-outline-dark rounded-pill py-2 px-3" (click)="back()">
      <img src="assets/icons/back_arrow_icon.svg" class="me-lg-2 white-icon" alt="" />
      <span class="align-middle d-none d-lg-inline">BACK</span>
    </button>
    <p class="d-inline-block ms-lg-4 mb-0 fs-5 page-heading text-center align-middle">
      My order Details
    </p>
  </div>
  
</div> -->
<div class="back-strip font-secondary bg-white firstDiv">
  <div class="container py-2 py-lg-3 d-flex" style="align-items: center;">        
              <button class="btn btn-outline-dark rounded-pill py-2 px-3" (click)="back()"><img
                 src="assets/icons/back_arrow_icon.svg" class="me-lg-2 white-icon" alt=""><span
                 class="align-middle d-none d-lg-inline">BACK</span></button>
              <p class="d-inline-block ms-lg-5 mb-0 fs-5 page-heading text-center align-middle"> My order Details</p>
  </div>
</div>
<section class="pt-1">
  @if (!result) {
    <div class="confirmation-loading-div">
      <div class="confirmation-loading-content">
        <p class=" fs-5">Loading your order details...<p>
        <img class="confirmation-loading-icon" src="assets/Loading_icon.gif" alt="Loading icon">
      </div>
    </div>
    
    }
    @if (result) {
      <div class="container reciept-container">
        <div *ngIf="result" class="information-div">
          <div class="information-main-div">
            <div class="orderHdr-div">
              <div class="invoice-num-div fw-bold pt-2">
                #{{ orderInfoDetail?.taxInvoiceNum.split("-")[2] }}
              </div>
              <div class="shop-name pt-2"><h5>{{ orderInfoDetail?.shopName }}</h5></div>
              <div class="item-count-price d-flex justify-content-between">
                <div class="count-class pt-2">Item: {{ this.totalQuantity }}</div>
                <div class="price-class fw-bold pt-2">
                  {{ currencySymbol
                  }}{{ orderInfoDetail.totalAmountPaid | number : "1.2-2" }}
                </div>
              </div>
              <p class="count-class pt-2">
                Pick Up: {{ getDisplayDate(orderInfoDetail)
                }}{{ orderInfoDetail?.deliveryDate.split(" ")[1] }}
                {{ orderInfoDetail?.deliveryTime }}
              </p>
              <div class="remark-main" *ngIf ="orderInfoDetail?.remarks !== null && orderInfoDetail?.remarks.split('\n')[0].toString().trim().length>0">Remarks: {{orderInfoDetail?.remarks.split("\n")[0]}}</div>
            </div>
          </div>
        </div>
    
        <div class="receipt-main-div d-flex justify-content-between pt-3">
          <button (click)="orderAgainClick();$event.stopPropagation();"
            class="btn btn-primary rounded-pill py-2 px-4 font-secondary btn-detail">
            ORDER AGAIN
          </button>
          @if (src==undefined || src=='') {
          <button (click)="receiptClick()" class="btn btn-primary rounded-pill py-2 px-4 font-secondary btn-detail">
            Receipt
          </button>
        }
        </div>
        @if (src==undefined || src=='') {
        <div class="timeline pt-4">
          <ul>
            <li class="list-item" *ngFor="let orderStatus of orderHistoryDetail">
              <div>
                <!-- <div class="outer-div-status"> -->
                <button class="status-class  btn-primary   px-4 py-1 btn-detail fs-6" [ngStyle]="{
                    'background-color': orderStatus.bgColor,
                    color: orderStatus.fgColor
                  }"  style="border: solid black;border-radius:10px;cursor:default;">
                  {{ orderStatus.displayText }}
                </button>
                <!-- </div> -->
                <p class="date p-1 fs-6" style="color: black">
                  {{ orderStatus.updateAt }}
                </p>
              </div>
            </li>
          </ul>
        </div>
      }
      @else {
        <div class="timeline pt-1"></div>
      }
        <div class="order-item-info">
          <div *ngFor="let itemDetail of orderDetailsInfo">
            {{ getCustomizationInfo(itemDetail)}}
            <div class="card order-card align-items-center p-1 p-lg-4 rounded-1 card-shadow mb-2 mb-lg-3">
              <div class="details-container">
                <div class="image-container">
                  <img *ngIf="
                      itemDetail.image !== null ||
                      itemDetail.image !== undefined ||
                      itemDetail.image !== ''
                    " onerror="this.src='assets/images/no-image-available.png'" class="item-image"
                    src="{{ imageUrl }}/images/product/{{ itemDetail.image }}" class="order-dish-img" width="95"
                    height="90" />
                </div>
                <div class="container d-grid grid-template-rows 1fr auto 1fr">
                  <div class="item-container">
                    <div class="item-details-container ">
                      <div class="item-name-div ">
                        
                        <div class="item-price-div d-flex justify-content-between">
                          <div class="price-quantity-div pt-1 me-2">
                            <h5>{{ itemDetail.name }}</h5>
                          </div>
                          <div>
                            @if ((itemDetail.customizations !== undefined && itemDetail.customizations !== null &&
                            itemDetail.customizations.length>0) ||( itemDetail.remarks !== null &&
                            itemDetail.remarks.toString().trim().length>0) ||(itemDetail.comboOptions !== undefined && itemDetail.comboOptions !== null &&
                            itemDetail.comboOptions.length>0) ||( itemDetail.remarks !== null &&
                            itemDetail.remarks.toString().trim().length>0)) {
                              <div type="button" class="order-dtl-info-more btn btn-primary rounded-pill font-secondary btn-detail"
                                (click)="orderCustomizationInfo(itemDetail);$event.stopPropagation();">
                                {{itemDetail.isShow?'LESS':'MORE'}}
                              </div>
                            }
                          
                          </div>
                         
                        </div>
                        @if(itemDetail.itemType == 1 ){
                          <div class="item-price-div d-flex justify-content-between">
                            <div class="price-quantity-div pt-1">
                              {{ itemDetail?.qty | number : "1.2-2" }} x
                              {{ itemDetail.fixedPrice | number : "1.2-2" }}
                            </div>
                            <div class="price-div fw-bold pt-1">
                              {{this.currencySymbol}} {{itemDetail?.itemTotal*itemDetail?.qty|
                                number : '1.2-2'}}
                            </div>
                           
                           
                          </div>
                        }
                        @if(itemDetail.itemType == 2 ){
                        <div class="item-price-div d-flex justify-content-between">
                          @if (itemDetail.discountAmount == 0) {
                            <div class="price-quantity-div pt-1">
                              {{ itemDetail?.qty | number : "1.2-2" }} x
                              {{ itemDetail.itemTotalWithCustomization | number : "1.2-2" }}
                            </div>
                          <div class="price-div fw-bold pt-1">
                            {{this.currencySymbol}} {{itemDetail?.itemTotalWithCustomization*itemDetail?.qty|
                              number : '1.2-2'}}
                          </div>
                        }@else {
                          <div class="price-quantity-div pt-1">
                            {{ itemDetail?.qty | number : "1.2-2" }} x
                            {{ this.totalAmountWithCustomization | number : "1.2-2" }}
                          </div>
                          <div class="price-div fw-bold pt-1">
                            {{this.currencySymbol}} {{this.totalPriceAfterDiscount|
                              number : '1.2-2'}}
                          </div>
                        }
                         
                         
                        </div>
                      }
                        @if(itemDetail.itemType == 3){
                          <div class="item-price-div d-flex justify-content-between">
                            <div class="price-quantity-div pt-1">
                             
                             {{ itemDetail?.qty | number : "1.2-2" }} x
                            {{ itemDetail.itemTotalWithComboCustomization| number : "1.2-2" }}
                         
                            </div>
                            <div class="price-div fw-bold pt-1">
                              {{this.currencySymbol}} {{itemDetail.itemTotalWithComboCustomization*itemDetail?.qty|
                                number : '1.2-2'}}
                            </div>
                           
                           
                          </div>
                         }

                        @if(itemDetail.discountAmount !== 0){
                          <div class="price-div  pt-2" style="color:red;">
                            {{itemDetail.discountName}} {{ currencySymbol
                            }}{{this.totalDiscountAmount | number : "1.2-2"}}
                            </div>
                        }
                       
                        
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              @if (itemDetail.isShow) {
              <div class="cust-dtl">
                @for (orderCustomizationItemInfo of  itemDetail.customizations; track $index) {
                  <div
                    class="customization-item-detail-sub-div pt-2">
                    <div class="item-detail-container-sub"
                      [ngClass]=" orderCustomizationItemInfo?.saleItemId ===itemDetail?.saleItemId ? 'item-detail-container-sub-main' : 'item-detail-container-sub-cust'">
                      <div class="item-name-div-sub">
                        {{orderCustomizationItemInfo.optionValueNameToPrint}}
                      </div>
                      
                      <div class="item-price-div-sub d-flex justify-content-between">
                        <div class="price-quantity-div">
                          {{itemDetail?.qty| number : '1.2-2'}} x
                            {{this.currencySymbol}}{{orderCustomizationItemInfo?.unitPriceWithTax| number : '1.2-2'}}
                            
                          </div>
                        <div class="price-div fw-bold"> {{this.currencySymbol}}
                          {{orderCustomizationItemInfo?.unitPriceWithTax*itemDetail?.qty|
                          number : '1.2-2'}}</div>
                      </div>
                    </div>
                  </div>
                 
                }
                @for (orderComboOptionInfo of  itemDetail.comboOptions; track $index) {
                  <div
                    class="customization-item-detail-sub-div pt-2">
                    <div class="item-detail-container-sub"
                    [ngClass]="orderComboOptionInfo?.saleItemId ===itemDetail?.saleItemId ? 'item-detail-container-sub-main' : 'item-detail-container-sub-combo'"
                      >
                      <div class="item-name-div-sub">
                        {{orderComboOptionInfo.optionValueName}}
                      </div>
                      <div class="item-price-div-sub d-flex justify-content-between">
                        <div class="price-quantity-div">
                          {{itemDetail?.qty| number : '1.2-2'}} x
                            {{this.currencySymbol}}{{orderComboOptionInfo?.unitPriceWithTax| number : '1.2-2'}}
                            
                          </div>
                        <div class="price-div fw-bold"> {{this.currencySymbol}}
                          {{orderComboOptionInfo?.unitPriceWithTax*itemDetail?.qty|
                          number : '1.2-2'}}</div>
                       
                      </div>
                    </div>
                    
                      <div class="customization-item-detail-sub-div pt-2">
                        <div *ngIf="orderComboOptionInfo.customizations && orderComboOptionInfo.customizations.length > 0">
                         
                            <div *ngFor="let customization of orderComboOptionInfo.customizations"  class="combo-option" style="margin-top: 10px;">
                              {{ customization.optionValueNameToPrint }} 
                              <div class="item-price-div-sub d-flex justify-content-between">
                                <div class="price-quantity-div">
                                  {{itemDetail?.qty| number : '1.2-2'}} x
                                    {{this.currencySymbol}}{{customization.fixedPrice| number : '1.2-2'}}
                                    
                                  </div>
                                <div class="price-div fw-bold"> {{this.currencySymbol}}
                                  {{customization.fixedPrice*itemDetail?.qty|
                                  number : '1.2-2'}}</div>
                               
                              </div>
                            </div>
                            
                            
                          
                        </div>
                      </div>
                    </div>
                  
                 
                }
              
              
                
                <div class="item-detail-container-sub-remark pt-2">
                  <div class="sub-remark-span pt-2" >{{itemDetail.remarks}}</div>
                </div>
              </div>
            }
            </div>
          </div>
        </div>
      </div>
        }
  
  <div class="receipt-detail" *ngIf="receiptMail">
    <div class="receipt-div-detail">
      <div id="receiptContentDiv" class="receiptContentDiv" [innerHTML]="htmlData"></div>
      <div class="reciept-option-container">
        <div class="close-button" (click)="outerReceiptClick(); $event.stopPropagation()">
          <span class="image-close-btn"><i class="fa fa-times" aria-hidden="true"></i></span>
        </div>
        <div class="download-receipt" (click)="downloadReceipt(); $event.stopPropagation()">
          <span class="download-receipt-button"><i class="fa fa-download" aria-hidden="true"></i></span>
        </div>
      </div>
    </div>
  </div>
</section>