import { Injectable, Inject } from '@angular/core';
import { StorageService } from '../../services/storage/storage.service';
import { SESSION_COMPANY_INFO,SESSION_AMENITY_LIST, SESSION_BUSINESS_TYPE_LIST, SESSION_DEPARTMENT_LIST, SESSION_ITEM_CATEGORY_LIST, SESSION_ONLINE_SERVICE_LIST, SESSION_SALE_ITEM_LIST, SESSION_SHOP_LIST, SESSION_SYSTEM_SETTINGS } from '../../app.constants';


@Injectable({
  providedIn: 'root'
})
export class SplashService {

  constructor(
    private storageService: StorageService
  ) { }

  /**
   * Determins the splash to show or not
   * @returns 
   */
  show(): boolean {

    var result: boolean = false;

    const sessionIdsToCheck: string[] = [
      SESSION_SALE_ITEM_LIST,
      SESSION_SHOP_LIST,
      SESSION_SYSTEM_SETTINGS,
      SESSION_AMENITY_LIST,
      SESSION_BUSINESS_TYPE_LIST,
      SESSION_COMPANY_INFO,
      SESSION_DEPARTMENT_LIST,
      SESSION_ITEM_CATEGORY_LIST,
      SESSION_ONLINE_SERVICE_LIST
    ];

    sessionIdsToCheck.forEach(sessionId => {
      const sessionData = sessionStorage.getItem(sessionId);
      if (sessionData === null) {
        result = true;
        return;
      }

    });

    return result;
  }
}