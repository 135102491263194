import { Routes } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { RecentShopsComponent } from './pages/recent-shops/recent-shops.component';
import { ShopDetailComponent } from './pages/shop-detail/shop-detail.component';
import { ShopItemsComponent } from './pages/shop-items/shop-items.component';
import { AboutComponent } from './pages/about/about.component';
import { ContactComponent } from './pages/contact/contact.component';
import { FaqComponent } from './pages/faq/faq.component';
import { ItemDetailComponent } from './pages/item-detail/item-detail.component';
import { MyAccountComponent } from './pages/my-account/my-account.component';
import { ShoppingCartComponent } from './pages/shopping-cart/shopping-cart.component';
import { ConfirmOrdersComponent } from './pages/confirm-orders/confirm-orders.component';
import { TermsConditionsComponent } from './pages/terms-conditions/terms-conditions.component';
import { PaymentInitComponent } from './pages/payment-init/payment-init.component';
import { PaymentSuccessComponent } from './pages/payment-success/payment-success.component';
import { PaymentFailComponent } from './pages/payment-fail/payment-fail.component';
import { ViewReceiptComponent } from './pages/view-receipt/view-receipt.component';
import { MyOrdersComponent } from './pages/my-orders/my-orders.component';
import { MyOrderDetailsComponent } from './pages/my-orders/my-order-details/my-order-details.component';
import { OrderConfirmComponent } from './pages/order-confirm/order-confirm.component';
import { AccountOptionComponent } from './pages/account-option/account-option.component';
import { MyRewardsComponent } from './pages/my-rewards/my-rewards.component';
import { CustomerRegistrationComponent } from './pages/customer-registration/customer-registration.component';
import { CustomerActivationComponent } from './pages/customer-activation/customer-activation.component';
import { ComboDetailComponent } from './pages/combo-detail/combo-detail.component';



export const routes: Routes = [
    {path: '', component:HomeComponent},
    {path: 'order', component:HomeComponent},
    {path: 'about', component:AboutComponent},
    {path: 'contact', component:ContactComponent},
    {path: 'faq', component:FaqComponent},
    {path: 'shopList', component:RecentShopsComponent},
    {path: 'shopDetail/:id', component:ShopDetailComponent},
    {path: 'shopItem',component:ShopItemsComponent},
    {path: 'account',component:MyAccountComponent},
    {path: 'itemDetail', component:ItemDetailComponent},
    {path: 'shoppingCart', component:ShoppingCartComponent},
    {path:'confirmOrders',component:OrderConfirmComponent},
    {path:'termsAndConditions',component:TermsConditionsComponent},
    {path:'payment-init',component:PaymentInitComponent},
    {path:'payment-success',component:PaymentSuccessComponent},
    {path:'payment-fail',component:PaymentFailComponent},
    {path:'view-receipt',component:ViewReceiptComponent},
    {path:'orders',component:MyOrdersComponent},
    {path:'order-details',component:MyOrderDetailsComponent},
    {path:'my-account',component:AccountOptionComponent},
    {path:'my-rewards',component:MyRewardsComponent },
    {path:'registraction',component:CustomerRegistrationComponent},
    {path:'activation/:token',component:CustomerActivationComponent},
    {path:'comboDetail',component:ComboDetailComponent},
    {path: '**', redirectTo: '', pathMatch: 'full' }
];

