import { Injectable, Inject } from '@angular/core';

import { SESSION_STORAGE, WebStorageService } from 'ngx-webstorage-service';
import {
    SESSION_BUSINESS_TYPE_LIST, SESSION_CART,
    SESSION_FILTERED_SALE_ITEM_LIST, 
    CUSTOMER_TYPE_GUEST,SESSION_SELECTED_SHOP_LIST,
    SESSION_OUTOFSTOCK_ITEM_LIST, SESSION_SALE_ITEM_LIST, 
    SESSION_SELECTED_SHOP, SESSION_SHOP_LIST, SESSION_PROMOTIONAL_SALEITEM_IDS, SESSION_OFFERS, SESSION_AUTHENTICATED_CUSTOMER, SESSION_ONLINE_SERVICE_LIST, SESSION_FILTERED_OFFERS, SESSION_CUSTOMER_ACTIVE_REWARDS, SESSION_APPLIED_REWARDS,
    SESSION_MASTER_CUSTOMIZATION,
    SESSION_MASTER_COMBO_CUSTOMIZATION
} from '../../app.constants'
import { Customer } from '../../models/customer.model';
// import { StartupService } from '../services/startup.service';
import { iif, isEmpty, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Time } from '@angular/common';
import { TimeService } from '../shared/time-service.service';
// import { analyzeAndValidateNgModules } from '@angular/compiler';
import { Router } from '@angular/router';
import { Cart, CartItem, Offer, OfferItem } from '../../models/cart.model';
import { StorageService } from '../storage/storage.service';
import { OOModule } from '../../modules/oo.module';
import { TaxService } from '../shared/tax.service';
import * as uuid from 'uuid';
import { environment } from '../../environments/environment';
import { NotificationService } from '../shared/notifications.service';
import { OrderScheduleComponent } from '../../pages/order-schedule/order-schedule.component';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogModel, MessageDialogComponent, MessageDialogService, NotificationDialogModel } from '../shared/message-dialog.service';

@Injectable({
  providedIn: 'root'
})
export class CartService {
  customerCartUrl: String;
  cartSaveUrl: string;
  cartdeleteUrl: string;
  recentOrderUrl:string;
  private _cart: Cart = { cartId: 0 };
  storage_session_data: any
  saleItemList: any;
  promotionalItems: any=[];
  outOfStockItemIds: any;
  filteredSaleItemListData: any;
  outOfStockItemInfo: any;
  cartTotalTax:any;
  promoItem:any;

  get cart(): Cart {
    if (this._cart === null || this._cart === undefined) {
      this.initializeCart();
    }
    return this._cart;
  }

   constructor(private storageService: StorageService,
    private timeService: TimeService,
    private http: HttpClient,
    private taxService: TaxService, 
    private router: Router,
    private notificationService:NotificationService,
    public dialog: MatDialog,
    private messageDialogService:MessageDialogService) {

      this.initializeCart();

    this.cartSaveUrl = environment.oos.url + '/cart/save';
    this.customerCartUrl = environment.oos.url + '/cart/get/';
    this.cartdeleteUrl = environment.oos.url + '/cart/delete';
    this.recentOrderUrl = environment.oos.url + '/customer/get-recent-orders';

  }
  /**
   * initialise  cart-
   * Check local storage for any cart object. If any get that cart else create new. Save it
   */
  initializeCart() {
    this._cart = JSON.parse(this.storageService.loadFromSession(SESSION_CART));
    if (this._cart === null || this._cart === undefined) {
      this._cart = {
        cartId: 0, customer:undefined, service: undefined, shop: undefined, orderDate: undefined, orderTime: undefined,
        deliveryDate: undefined, deliveryTime: undefined, businessType: undefined, pickupLocationId: undefined, items: [], remarks: undefined
      };
      this.setToSession();
    }

  }
  setToSession() {
    this.storageService.saveToSession(SESSION_CART, JSON.stringify(this._cart));
    this.storage_session_data = this.storageService.loadFromSession(SESSION_CART);
  }
  /**
    * add item to cart
    */
  addItem(itemInfo: any) {
    itemInfo.itemId = uuid.v4();
    this._cart.items?.push(itemInfo);
    this.setToSession();
    this.notificationService.notifyCartChange("added");
  }
  /**
    * Clear cart
    */
  clear() {
    this._cart.items = [];
    this.setToSession();
  }
  
  /**
    * add item to cart after the given item.
    */
  addItemAfter(itemAter: any, newItem: any) {
    // newItem.itemId = UUID.UUID();
    // const index = this._cart.items.findIndex(e => e.itemId === itemAter.itemId);
    // this._cart.items.splice(index + 1, 0, newItem);
    this.setToSession();
  }

  setCustomer(customer: any) {
    this._cart.customer = customer;
    this.setToSession();
  }

  /**
   * Set recent order to the cart
   * @param recentOrder 
   */
  setRecentOrder(recentOrder: any) {
    this._cart.customer!.recentOrder= recentOrder;
    this.setToSession();
  }

  getCustomer(): any {
    return this._cart.customer;

 }

  setReOrder(isReorder: boolean): void {
    this._cart.reOrder=isReorder;
  }

  getReorder(): any {  //boolian change to any
     return (this._cart.reOrder!==undefined && this._cart.reOrder)?true:false;
  }


  setService(service: any) {
    this._cart.service = service;
    this.setToSession();
  }

  getService():any{
    return this._cart.service;
  }

  getCart():any {
    return this._cart;
  }

  setBusinessType(businessType: any) {
    this._cart.businessType = businessType;
    this.setToSession();
  }

  getBuisinessType():any{
    return  this._cart.businessType;
  }

  setPickupLocation(pickupLocationId: any) {
    this._cart.pickupLocationId = pickupLocationId;
    this.setToSession();
    }

  getPickupLocation():any{
    // return this._cart.pickupLocation;
  }

  deleteItem(itemInfo: any) {
    let index = -1;
    index = this._cart.items!.findIndex(e => e.itemId === itemInfo.itemId);
    this._cart.items?.splice(index, 1);
    this.setToSession();
    if(itemInfo.relatedItemId!="")
      this.unsetRelatedItem(itemInfo.relatedItemId);
  }
  deleteAllItem(itemInfo: any) {
    const index = -1;
    index != this._cart.items?.findIndex(e => e.itemId === itemInfo.itemId);
    this._cart.items?.splice(index, 1);
    this.setToSession();
  }
  setDeliveryDate(deliveryDate: any) {
    this._cart.deliveryDate = deliveryDate;
    this.setToSession();
    this.notificationService.notifyDateChange(deliveryDate);
  }

  getDeliveryDate():any {
    return this._cart.deliveryDate;
  }

  setDeliveryTime(deliveryTime: any) {
    this._cart.deliveryTime = deliveryTime;
    this.setToSession();
    this.notificationService.notifyTimeChange(deliveryTime);
  }

  getDeliveryTime():any {
    return this._cart.deliveryTime;
  }

  setShop(shop: any) {
    this._cart.shop = shop;
    this.storageService.saveToSession(SESSION_SELECTED_SHOP,JSON.stringify(shop));
    this.setToSession();
  }

  getShop():any{
    return this._cart.shop;
  }

  resetCustomer(){
    this._cart.customer=undefined;
      this.setToSession();
  }
  resetCart() {
    this._cart.reOrder=false;
    this._cart.shop = undefined;
    this._cart.items = [];
    // this._cart.deliveryDate = undefined;
    // this._cart.deliveryTime = undefined;
    this._cart.orderDate = undefined;
    this._cart.orderTime = undefined;
    this._cart.remarks = undefined;
    // this._cart.service = undefined;
    this._cart.businessType = undefined;
    this._cart.pickupLocationId = undefined;
    this.setToSession();

  }
  /**
   * set remarks
   * @param remarks
   */
  setRemarks(remarks: any) {
    this._cart.remarks = remarks;
    this.setToSession();
  }

  getRemarks():any{
    this._cart.remarks ;
  }

  /**
   * find cart total
   */
  getCartTotal() {
    let total = 0;
    this._cart.items?.forEach(e => {
        total = total + (e.netTotal + (e.netCustomizationTotal !== undefined ? e.netCustomizationTotal : 0));
    });
    return total;
  }
/**
   * find cart total
   */
getCustCartTotal(cart:any) {
  let total = 0;
   cart.items?.forEach((e:any) => {
      total = total + (e.netTotal 
              + (e.netCustomizationTotal !== undefined ? e.netCustomizationTotal : 0)  
              - (e.offerAmount !== undefined ? e.offerAmount : 0)  
              - (e.customizationOfferAmount !== undefined ? e.customizationOfferAmount : 0)) ;
  });
  return total;
}
  /**
   * find cart total
   */
  getCartTotalTax() {
    this.cartTotalTax = 0;
    this._cart.items?.forEach(e => {
        this.cartTotalTax = this.cartTotalTax + e.totalTax ;
    });
    return this.cartTotalTax;
  }

  /**
   * find total  quantity
   */
  getTotalQuantity() {
    let quantity = 0;
    if (this._cart === undefined) {
        this.initializeCart();
    }
    this._cart.items?.forEach(e => {
        quantity = quantity + e.quantity;
    });
    return quantity;
  }
  /**
   *
   * @param cartItem change cart item
   */
  changeItem(cartItem: any) {
    let index = -1;
    index = this._cart.items!.findIndex(e => e.itemId === cartItem.itemId);
    if (index !== -1) {
        var citem=this.resetCartItem(cartItem);
        this._cart.items![index].totalPrice = citem.totalPrice;
        this._cart.items![index].quantity = citem.quantity;
        this._cart.items![index].customization = citem.customization;
        this._cart.items![index].customizationUnitPriceWithTax = citem.customizationUnitPriceWithTax;
        this._cart.items![index].netCustomizationTotal = citem.netCustomizationTotal;
        this._cart.items![index].customizationTotalTax = citem.customizationTotalTax;
        this.taxService.reCalculateTax(this._cart.items![index]);
        this.setToSession();
    }
  }

  /**
   * Reset the cart item before settin backl to session.
   * cart item may contains offer amount which need to reset to 0 before saving to session
   * @param item 
   * @returns 
   */
  resetCartItem(item:any):CartItem{
    var cartItem:CartItem= JSON.parse(JSON.stringify(item));
    cartItem.customization.forEach((co:any)=>{
      co.optionValue.forEach((op:any)=>{
        op.offerAmount=0;
      })
    })
    item = cartItem;
    return item;
  }  /**
   * 
   * @param item Updates the customization
   */
  setCustomization(item: any) {
    var customizationDetail = item.customization;
    var customizationUnitPriceWithTax = 0;
    var netCustomizationTotal = 0;
    var customizationTotalTax=0;
    for (let cd of customizationDetail) {
      for (let ov of cd.optionValue) {
        if (ov.isSelected === 1) {
          ov.quantity = item.quantity;
          this.taxService.reCalculateTax(ov);
          customizationUnitPriceWithTax += (ov.isDefault) ? 0 : ov.unitPriceWithTax;
          netCustomizationTotal += (ov.isDefault) ? 0 : ov.netTotal;
          customizationTotalTax += (ov.isDefault) ? 0 : ov.totalTax;
        } else {
          ov.quantity = 1;
          this.taxService.reCalculateTax(ov);
        }
      }
    }
    item.customizationUnitPriceWithTax = customizationUnitPriceWithTax;
    item.netCustomizationTotal = netCustomizationTotal;
    item.customizationTotalTax = customizationTotalTax;
  }


  /**
   * 
   * @param item Updates the customization
   */
  setCombo(item: any) {
    var customizationDetail = item.comboOptions;
    var customizationUnitPriceWithTax = 0;
    var netCustomizationTotal = 0;
    var customizationTotalTax=0;
    for (let cd of customizationDetail) {
      for (let ov of cd.optionValue) {
        if (ov.isSelected === 1) {
          ov.quantity = item.quantity;
          this.taxService.reCalculateTax(ov);
          customizationUnitPriceWithTax += (ov.unitPriceWithTax+ov.customizationUnitPriceWithTax);
          netCustomizationTotal += ov.netTotal;
          customizationTotalTax += ov.totalTax;
        } else {
          ov.quantity = 1;
          this.taxService.reCalculateTax(ov);
        }
      }
    }
    item.customizationUnitPriceWithTax = customizationUnitPriceWithTax;
    item.netCustomizationTotal = netCustomizationTotal;
    item.customizationTotalTax = customizationTotalTax;
  }
 /**
   * 
   * @param offer 
   * Check offers enabeled for to apply offer for current customer
   */
 isOfferEnabledForCust(offer: any): boolean {
  var isValid=true;
      if (offer.enabled_for !== null && offer.enabled_for !== undefined) {
          if(this.isGuestLogin()){
            if(!offer.enabled_for.some((pr: any) => pr==CUSTOMER_TYPE_GUEST)){
              isValid=false;
            }
          }
        }
        return isValid;
}
  /**
   * check whether guest
   */
  isGuestLogin(): any { //boolean change change to any
    return (this._cart.customer ==undefined || this._cart.customer.customerType === CUSTOMER_TYPE_GUEST);
  }

  getCustomizationDetail(saleItemInfo: any): any {
    let customizationDetail = [];
    if (saleItemInfo.itemCustOptionIds != null && saleItemInfo.itemCustOptionIds.length > 0) {
        let allCustmizationMasterDtl = JSON.parse(this.storageService.loadFromSession(SESSION_MASTER_CUSTOMIZATION));
        customizationDetail = allCustmizationMasterDtl.filter((ac:any) =>
            saleItemInfo.itemCustOptionIds.find((e:any) => e === ac.custOptId)
        );

        if (customizationDetail !== undefined && customizationDetail.length > 0) {
            customizationDetail = JSON.parse(JSON.stringify(customizationDetail));
        }
    }
    return customizationDetail;
}

/**
 * Get the combo details for the customization.
 * @param saleItemInfo 
 * @returns 
 */
getComboOptionDetail(saleItemInfo: any): any {
  this.saleItemList = JSON.parse(this.storageService.loadFromSession(SESSION_SALE_ITEM_LIST));
  let comboOptionDetails = [];
  if (saleItemInfo.itemComboOptionIds != null && saleItemInfo.itemComboOptionIds.length > 0) {
      let allComboOptionMasterDtl = JSON.parse(this.storageService.loadFromSession(SESSION_MASTER_COMBO_CUSTOMIZATION));
      comboOptionDetails = allComboOptionMasterDtl.filter((ac:any) =>
          saleItemInfo.itemComboOptionIds.find((e:any) => e === ac.comboOptId)
      );
      if (comboOptionDetails !== undefined && comboOptionDetails.length > 0) {
          comboOptionDetails = JSON.parse(JSON.stringify(comboOptionDetails));
         comboOptionDetails.forEach((ci:any) => {
            this.saleItemList.filter((fs: any) => ci.optionValue.find((os: any) =>{ 
              if(fs.saleItemId === os.saleItemId){
                fs.fixedPrice=os.price;
                fs.unitPriceWithTax=os.price;
                os.customizationUnitPriceWithTax=0;
                os.saleItem=JSON.parse(JSON.stringify(fs));
                this.taxService.reCalculateTax(fs);
              }
            }));
          })
    
      }
  }
  return comboOptionDetails;
}

/**
 *
 * @param reOrderItem
 * re recent order shop is set to cart.
 */
setRecentOrderShopToCart(reOrderItem:any) {
  const selectedShopID = reOrderItem.shopId;
  let shopList = JSON.parse(this.storageService.loadFromSession(SESSION_SHOP_LIST));
  const selectedShop = shopList.filter((shp:any) => shp.id === selectedShopID);
  this.setShop(selectedShop[0]);
  this.storageService.saveToSession(SESSION_SELECTED_SHOP,JSON.stringify(selectedShop[0]));
}

/**
 *
 * @param reOrderItem
 * re recent order shop is set to cart.
 */
getRecentOrderShopToCart(reOrderItem:any) {
  const selectedShopID = reOrderItem.shopId;
  let shopList = JSON.parse(this.storageService.loadFromSession(SESSION_SHOP_LIST));
  const selectedShop = shopList.filter((shp:any) => shp.id === selectedShopID);
  return selectedShop[0];
  // this.storageService.saveToSession(SESSION_SELECTED_SHOP,JSON.stringify(selectedShop[0]));
}

/**
 *
 * @returns
 * load promotionalSaleItem from filterd saleItem list based on promotional sale item Ids;
 * then fetch promotional items not in cart
 * then filter promotional items from out of stock items
 */
getValidPromotionalItemData() {
  let filteredSaleItemList = JSON.parse(this.storageService.loadFromSession(SESSION_FILTERED_SALE_ITEM_LIST));
  let promotionalSaleItemIds = JSON.parse(this.storageService.loadFromSession(SESSION_PROMOTIONAL_SALEITEM_IDS));
  this.outOfStockItemIds = JSON.parse(this.storageService.loadFromSession(SESSION_OUTOFSTOCK_ITEM_LIST));

  let promotionalSaleItem = (filteredSaleItemList !== undefined && filteredSaleItemList !== null && promotionalSaleItemIds !== undefined && promotionalSaleItemIds !== null) ?
      filteredSaleItemList.filter((fs:any) => promotionalSaleItemIds.findIndex((ps:any) => fs.saleItemId === ps) >= 0) : [];
  // let promoItemNotInCart = (promotionalSaleItem !== undefined && promotionalSaleItem !== null && this.cart.items !== undefined && this.cart.items !== null)?
  //                           promotionalSaleItem.filter(o1 => !this.cart.items.some(o2 => o1.saleItemId === o2.saleItemId)):[];
  // this.promotionalItems = (promoItemNotInCart !== undefined && promoItemNotInCart !== null && this.outOfStockItemIds !== undefined && this.outOfStockItemIds !== null)?
  //                           promoItemNotInCart.filter(pic => !this.outOfStockItemIds.some(os => pic.id === os)):[];

  this.promotionalItems = (promotionalSaleItem !== undefined && promotionalSaleItem !== null && this.outOfStockItemIds !== undefined && this.outOfStockItemIds !== null) ?
      promotionalSaleItem.filter((pic:any) => !this.outOfStockItemIds.some((os:any) => pic.id === os)) : [];

  return this.promotionalItems;
}

/**
 * get Promotional item from cart item list
 * @param promoItemId 
 */
getItemWithPromotion(promoItemId:any){
  var cart=this.getCustomerCart();
  cart.items.forEach((pr:any)=>{
    pr.offers.some((ar: any) =>{
      if(ar.offerId==promoItemId){
        this.promoItem=pr;
      }
    })
  })
  return this.promoItem;
}
  /**
   * check whether guest or customer
   * return guest if guest or return customer
   */
  getCustomerType(): string {
    return this.isGuestLogin() ? 'guest' : 'customer';
  }

  /**
   * cart param
   */
  getCartParam(): any {

    // const deliveryDate = this._cart.deliveryDate.date;
    // const deliveryDateFormatted = deliveryDate;
    const today = new Date(this.timeService.serverTime);
    //   const today = new Date();
    const date = today.getFullYear() + '/' + (today.getMonth() + 1) + '/' + today.getDate();
    const time = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
    const custCart= this.getCustomerCart();
    const cartParam = {
        customerId: custCart.customer?.id, service: custCart.service, shop: custCart.shop,
        orderDate: date, orderTime: time, deliveryDate: custCart.deliveryDate.date,
        deliveryTime:custCart.deliveryTime, businessType: custCart.businessType,
        pickupLocationId: custCart.pickupLocationId,
        items:  custCart.items, remarks: custCart.remarks
    };
    return cartParam;
  }


  isCartValid(): boolean {

      return (this._cart !== undefined && this._cart.items !== undefined && this._cart.items.length > 0);
  }

  saveCart(): Observable<any> {

      const cartParam = this.getCartParam();
      return this.http.post<any>(this.cartSaveUrl, cartParam)
          .pipe(
          );
  }

  cartdelete(): Observable<any> {

      return this.http.delete<any>(this.cartdeleteUrl + '/' + this._cart.customer?.id)
          .pipe(
          );
  }

  getCartBasedOnCustomerId(customerId: any): Observable<any> {
    const url = this.customerCartUrl + customerId;
    return this.http.get<any>(url)
      .pipe(
    );
  }

  /**
 * get selected customization option value data
 * @param item
 * @returns
 */
getSelectedCustomizationOptionValueData(item:any) {
  let cutstomisationSelectedArray = [];
  for (let cd of item.customization) {
      for (let ov of cd.optionValue) {
          if (ov.isDefault === 0 && ov.isSelected === 1) {
              cutstomisationSelectedArray.push(ov);
          }
      }
  }
  return cutstomisationSelectedArray;
}
getCustomerCart():any{
  var customerCart=JSON.parse(JSON.stringify(this._cart));
  const offerData=this.storageService.loadFromSession(SESSION_FILTERED_OFFERS);
  const rewardData=this.storageService.loadFromSession(SESSION_APPLIED_REWARDS);
  var rewards =(rewardData!==null && rewardData!==undefined && rewardData!=="undefined")? JSON.parse(rewardData):[];
 
  var filteredOffers =(offerData!==null && offerData!==undefined && offerData!=="undefined")? JSON.parse(offerData):[];
  // var offers= Object.assign({}, filteredOffers, rewards);
  var offers=[...rewards,...filteredOffers];
  let itemGrpList = new Map<any, number>();
  let qty:any;

  //reset related item id array
  this._cart.items?.forEach(e => {
    e.relatedItemId=[];
  });

  // Grouping item qtys for applying offers 
  this._cart.items?.forEach(e => {
    if(itemGrpList.get(e.saleItemId)!=undefined)
      qty=itemGrpList.get(e.saleItemId);
    else
      qty=0;

    itemGrpList.set(e.saleItemId,qty + e.quantity);
  }); 
 
  // offers.find()
  var discOffersBasedOnAmt  = offers.filter((offer:any) => offer.discount.buy.based_on=="order_amount" &&  offer.discount.offer.type=="discount" );
  var discOffersBasedOnItem  = offers.filter((offer:any) =>  offer.discount.buy.based_on=="sale_item" &&  offer.discount.offer.type=="discount");
  var itemOffersBasedOnItem  = offers.filter((offer:any) =>  offer.discount.buy.based_on=="sale_item" &&  offer.discount.offer.type=="sale_item");
  var itemOffersBasedOnAmt  = offers.filter((offer:any) =>  offer.discount.buy.based_on=="order_amount" &&  offer.discount.offer.type=="sale_item");
 
  let buySaleItemIds : String[] = [];
  let buyItems:any[]=[];
  let jsonOfferItems : any[] = [];
  let jsonPendingOfferItems : any[] = [];  
  var flagExist=false;

 
  // Store offer as item into jsonOfferItems 
  
  // Store offer items based on amount to json array
  itemOffersBasedOnAmt.forEach((e:any) => {
    // Access each item here
    // "description":"Your bill amount is $" +  amount + " or more",
    var amount=e.discount.buy.trigger_on.amount;
    flagExist=false;
    if ( this.isOfferEnabledForCust(e) &&  this.getCartTotal()>=amount){
      let itemArray:any[] = [];
      e.discount.offer.offer.forEach((ofritem:any) => {
        if(this._cart.items?.some(item => item.saleItemId === ofritem.id)){
          itemArray.push(this._cart.items?.find(item => item.saleItemId === ofritem.id));
        }
      });
      itemArray.sort((a:any,b:any)=>a.fixedPrice-b.fixedPrice);
      if (itemArray.length>0 && (this.getCartTotal()-(itemArray[0].fixedPrice+itemArray[0].customizationUnitPriceWithTax))>=amount ){
        flagExist=true;
        let offer:Offer={"offerId":e.id,    
                          "mainItemId":0,
                          "name" :e.name,
                          "description":e.description,
                          "offers" : e.discount.offer.offer
                        };
        jsonOfferItems.push({id: uuid.v4(),offer: offer,isUsed:false });
      }
    }
    if (flagExist==false){
        let offer:Offer={"offerId":e.id,    
        "mainItemId":0,
        "name" :e.name,
        "description":e.description,
        "offers" : e.discount.offer.offer
      };
      jsonPendingOfferItems.push({"amount":amount,offer: offer, isUsed:false });
    }
 
  });

   // Store offer items based on sale item to json array
  itemOffersBasedOnItem.forEach((e:any) => {
    if (this.isOfferEnabledForCust(e)){
      // Access each item here
      e.discount.buy.trigger_on.forEach((item:any) => {
        qty=itemGrpList.get(item.id);
        if (qty!=undefined){

          buySaleItemIds.push(item.id);
          for (let i = 0; i < qty; i++) {
            let offer:Offer={"offerId":e.id,    
                            "mainItemId":item.id,
                            "name" :e.name,
                            "description":e.description,
                            "offers" : e.discount.offer.offer
                        };
            jsonOfferItems.push({id: uuid.v4(),offer: offer,isUsed:false });
          }
        }
      });
    }
  });

  // Store disc offer  based on sale item to json array
  discOffersBasedOnItem.forEach((e:any) => {
    if (this.isOfferEnabledForCust(e)){
      // Access each item here
      e.discount.buy.trigger_on.forEach((item:any) => {
        qty=itemGrpList.get(item.id);
        if (qty!=undefined){
          for (let i = 0; i < qty; i++) {
            let offer:any={
                              "offerId":e.id,    
                              "mainItemId":item.id,
                              "name" :e.name,
                              "description":e.description,
                              "offers" : [{
                                  "id":item.id,
                                  "qty":1,
                                  "is_percentage" :e.discount.offer.offer.is_percentage,
                                  "variance" :e.discount.offer.offer.variance
                              }] 
                        };
            jsonOfferItems.push({id: uuid.v4(),offer: offer,isUsed:false });
          }
        }
      });
    }
  });

  let discount:any; 
  let selectedUuid:any;
  let selectedOffer:any;
  let offerItem:OfferItem;
  let offerItemCustomization:OfferItem;
  let discountAmount:number;

  customerCart.offers = [];

  //apply discount to the cart 
  
  // apply offers as bill discount based on order_amount
 let flag:boolean=false;
 discOffersBasedOnAmt.forEach((e:any) => {
  // Access each item here
  // "description":"Your bill amount is $" +  amount + " or more",
  var amount=e.discount.buy.trigger_on.amount;
  if (!flag && this.isOfferEnabledForCust(e) && this.getCartTotal()>=amount){
     
            discountAmount=(e.discount.offer.offer.is_percentage==0?
                            e.discount.offer.offer.variance:
                            this.getCartTotal() * e.discount.offer.offer.variance/100);
            offerItem={
			        itemId: 0,
              offerId: e.id,
              name:e.name,
              description : e.description,
              isPercentage: e.discount.offer.offer.is_percentage,
              variance: e.discount.offer.offer.variance, 
              amount:discountAmount
            };
            customerCart.offers?.push(offerItem);
            flag=true;
  }

});

  // apply discount to cart items 
  let offerAmt:number=0;
  let offerAmtCustomization:number=0;
  let totalPrice:number=0;

  let grandTotal:number=0;
  let totalTax:number=0;
  let totalOffer:number=0;
  let netTotal:number=0;
  let relatedItemFlag:boolean=false;

  //to set the relation of " Buy 1 Get 1 Offer ", split and keep buy items in a array
  customerCart.items.forEach((e:any)=> {
    for (let index = 0; index < buySaleItemIds.length; index++) {
      if (buySaleItemIds[index] === e.saleItemId) {
        for (let i = 0; i < e.quantity; i++) {
          buyItems.push({id: uuid.v4(),"buySaleItemId": e.saleItemId,"buyItemId":e.itemId,isUsed:false });
        }
      }
    }   
  });

  // customerCart.items.sort((a:any,b:any)=>b.fixedPrice-a.fixedPrice);
  customerCart.items.forEach((e:any) => {
     offerAmt=0;
    offerAmtCustomization=0;
    e.offers = [];
    qty=e.quantity;
    while(qty>0){
      selectedUuid="";
      jsonOfferItems.forEach((jsonOffer:any) => {
        jsonOffer.offer.offers.forEach((ofr:any) => {
         
          if (e.saleItemId==ofr.id &&  jsonOffer.isUsed==false && selectedUuid==""){
            // buyItems.push({id: uuid.v4(),"buySaleItemId": e.saleItemId,"buyItemId":e.itemId,isUsed:false });
            
            // set relation id 
            if (jsonOffer.offer.mainItemId!=0){
              relatedItemFlag=false;
              buyItems.forEach((jsonBuyItem:any) => {
                if (jsonBuyItem.buySaleItemId==jsonOffer.offer.mainItemId && 
                  jsonBuyItem.isUsed==false && relatedItemFlag==false){
                    jsonBuyItem.isUsed=true;
                    this.setRelatedItem(jsonBuyItem.buyItemId,e.itemId);
                    relatedItemFlag=true;
                  }

              });
            }


            jsonOffer.isUsed=true;
            selectedUuid=jsonOffer.id;
            selectedOffer=ofr;
            totalPrice=(e.fixedPrice  );

           discountAmount=(selectedOffer.is_percentage==0?
                            selectedOffer.variance:
                            totalPrice *  selectedOffer.variance/100);
            
            discountAmount =parseFloat((Math.round(discountAmount * 100) / 100).toFixed(2));
            // discountAmount=parseFloat(discountAmount.toFixed(2));
            offerItem={
			        itemId: jsonOffer.offer.mainItemId,
              offerId: jsonOffer.offer.offerId,
              name:jsonOffer.offer.name,
              description : jsonOffer.offer.description,
              isPercentage: selectedOffer.is_percentage,
              variance: selectedOffer.variance, 
              amount:discountAmount,
              offer:offers.find((of:any)=>of.id==jsonOffer.offer.offerId) 
            };
            offerAmt=offerAmt+ discountAmount;
 
            if ( e.customization!=undefined){
              var customizationDetail = e.customization;
             
              for (let cd of customizationDetail) {
                for (let ov of cd.optionValue) {
                  if (ov.isSelected === 1) {
                    totalPrice=(ov.fixedPrice );
                     discountAmount=(selectedOffer.is_percentage==0?
                                    selectedOffer.variance:
                                    totalPrice *  selectedOffer.variance/100);
                    discountAmount = parseFloat((Math.round(discountAmount * 100) / 100).toFixed(2));
                    // discountAmount=parseFloat(discountAmount.toFixed(2));
                    ov.offerAmount=ov.offerAmount+ discountAmount ;
                    offerAmtCustomization=offerAmtCustomization+ discountAmount;
                    this.taxService.reCalculateTax(ov);
                     
                  }  
                }
              }

            }
          }
        });
      });

      if (selectedUuid!=""){
        e.offers?.push(offerItem)
        }
      --qty;
    }
     e.offerAmount=(isNaN(e.offerAmount)?0:e.offerAmount) + offerAmt;
    e.customizationOfferAmount=(isNaN(e.customizationOfferAmount)?0:e.customizationOfferAmount) + offerAmtCustomization;
    
    e.offerAmount =parseFloat((Math.round((e.offerAmount )  * 100) / 100).toFixed(2));
    e.customizationOfferAmount =parseFloat((Math.round((e.customizationOfferAmount )  * 100) / 100).toFixed(2));
    e.totalOfferAmount =parseFloat((Math.round(( e.offerAmount+e.customizationOfferAmount )  * 100) / 100).toFixed(2));
              
        // e.offerAmount =parseFloat((e.offerAmount).toFixed(2));
        // e.customizationOfferAmount =parseFloat((e.customizationOfferAmount).toFixed(2));
        // e.totalOfferAmount =parseFloat((e.offerAmount+e.customizationOfferAmount ).toFixed(2));
        
    grandTotal=grandTotal + (e.totalPrice - e.totalTax) + (e.netCustomizationTotal-e.customizationTotalTax);
    totalTax=totalTax + e.totalTax + e.customizationTotalTax ;
    netTotal=netTotal + e.totalPrice + e.netCustomizationTotal;
    totalOffer=totalOffer + offerAmt + offerAmtCustomization;
  });
  customerCart.grandTotal=grandTotal;
  customerCart.totalTax=totalTax;
  customerCart.netTotal=netTotal;
  customerCart.totalOffer=totalOffer;
 

  // check for pending offers 
  customerCart.items.forEach((e:any) => {
    if(e.offerAmount==null || e.offerAmount==0){
      jsonPendingOfferItems.forEach((jsonOffer:any) => {
        jsonOffer.offer.offers.forEach((ofr:any) => {
         
          if (e.saleItemId==ofr.id &&  jsonOffer.isUsed==false  ){
            var requiredAmount=jsonOffer.amount - (customerCart.netTotal -(e.fixedPrice+e.customizationUnitPriceWithTax))
            jsonOffer.isUsed=true;
            // e.offerPendingMessage=jsonOffer.offer.name + " offer is not applied because the cart amount is less than $" + jsonOffer.amount;
            e.offerPendingMessage="To get '"+jsonOffer.offer.name+"' on this item, please spend $"+requiredAmount.toFixed(2)+" more."

          }
        });
      });
    }
  });
 
  return customerCart;
}

getUnitpriceWithTax(taxCalculationMethod:any, fixedPrice:any, tax1Pc:any) {
  return (taxCalculationMethod === 1) ? (fixedPrice + (fixedPrice * tax1Pc / 100)) : fixedPrice;
}
  /**
   * 
   * @param reOrderItem 
   * set recent order information to cart.
   */
  setCartFromRecentOrders(reOrderItem:any) {
      let reorderShop = this.getRecentOrderShopToCart(reOrderItem);
      let reorderBusinessType = this.getRecentOrderBusinessTypeTocart(reOrderItem.buisnessTypeId);
      const dlgData = { businessType: reorderBusinessType.code, shop: reorderShop, redirectUrl: "/shoppingCart", msg: "",src:"reorder" }
      this.dialog.open(OrderScheduleComponent, {
        panelClass: 'schedule-dialog',
        data: dlgData
      }).afterClosed().subscribe((result) => {
              if(result==true){
                this.resetCart();
                this.setReOrder(true);
                this.setRecentOrderCustomerToCart(reOrderItem);
                this.setRecentOrderShopToCart(reOrderItem);
                this.setServiceToCart(reOrderItem);
                this.setRecentOrderBusinessTypeTocart(reOrderItem.buisnessTypeId);
                this.setRemarks((reOrderItem.remarks !== null) ? reOrderItem.remarks.split("\n")[0] : '');
                this.setRecentOrderItemsTocart(reOrderItem.items);
              }
              if(result==false){
                var dailogData: ConfirmDialogModel = {
                  message: `About to cancel your re-ordering process. Are you sure?`,
                  title:"Cancel Reorder!",
                  yesButtonCaption:"Yes",
                  noButtonCaption:"No"
                }
            
                this.messageDialogService.showConfirmDialog(dailogData).subscribe(result => {
                  if (result == "Yes") {
                  }
                  else{
                    this.setCartFromRecentOrders(reOrderItem);
                  }
                });
              }
            })

}
/**
 * Order schedule pop up when click order again
 */
orderSchedulePopUp(dlgData:any,reOrderItem:any){
  this.dialog.open(OrderScheduleComponent, {
    panelClass: 'schedule-dialog',
    data: dlgData
  }).afterClosed().subscribe((result) => {
    if(result==true){
      this.setRecentOrderItemsTocart(reOrderItem.items);
    }
  });
}
/**
 *
 * @param reOrderItem
 * set recent order customer information to cart
 */
setRecentOrderCustomerToCart(recentOrder:any) {
    this.setRecentOrder(recentOrder);
}

/**
 *
 * @param recentOrder
 * set recent order customer information to cart
 */
setRecentOrderToCart(reOrderItem:any) {
  let customer = JSON.parse(this.storageService.loadFromSession(SESSION_AUTHENTICATED_CUSTOMER));
  this.setCustomer(customer);
}

/**
 *
 * @param reOrderItem
 * set recent order service type to cart.
 */
setServiceToCart(reOrderItem:any) {
    let service: any;
    let serviceList = JSON.parse(this.storageService.loadFromSession(SESSION_ONLINE_SERVICE_LIST));
    service = serviceList.filter((sl:any) => sl.id == reOrderItem.serviceId);
    this.setService(service[0]);
}

/**
 *
 * @param reOrderItem
 * set recent order service type to cart.
 */
getServiceToCart(reOrderItem:any) {
  let service: any;
  let serviceList = JSON.parse(this.storageService.loadFromSession(SESSION_ONLINE_SERVICE_LIST));
  service = serviceList.filter((sl:any) => sl.id == reOrderItem.serviceId);
  return service[0];
}

/**
 *
 * @param businessTypeId
 * set recent order business type info to cart.
 */
setRecentOrderBusinessTypeTocart(businessTypeId:any) {
    let currentBusinessInfo: any;
    let businessTypeList = JSON.parse(this.storageService.loadFromSession(SESSION_BUSINESS_TYPE_LIST));
    currentBusinessInfo = businessTypeList.filter((bt:any) => bt.id == businessTypeId);
    this.setBusinessType(currentBusinessInfo[0]);
}

/**
 *
 * @param businessTypeId
 * set recent order business type info to cart.
 */
getRecentOrderBusinessTypeTocart(businessTypeId:any) {
  let currentBusinessInfo: any;
  let businessTypeList = JSON.parse(this.storageService.loadFromSession(SESSION_BUSINESS_TYPE_LIST));
  currentBusinessInfo = businessTypeList.filter((bt:any) => bt.id == businessTypeId);
  return currentBusinessInfo[0];
}

/**
 * set reordered item to cart.
 * if an item is present in the master sale item,
 *  then fetch that data from there.
 * if not,reorder item information is considered.
 *
 * In the price validation , compare prices of master saleitem and reordered item.
 * @param reOrderItems
 */
setRecentOrderItemsTocart(reOrderItem:any) {
    this.saleItemList = JSON.parse(this.storageService.loadFromSession(SESSION_SALE_ITEM_LIST));
    for (let reItem of reOrderItem) {
        let itemInfo;
        let itemCustomization = [];
        let item: CartItem;
        let isCust = true;
        reItem.customizationUnitPriceWithTax = ((reItem.customizationUnitPriceWithTax !== undefined) ? reItem.customizationUnitPriceWithTax : 0)
        itemInfo = this.saleItemList.find((si:any) => si.saleItemId === reItem.saleItemId);
        if (itemInfo !== null && itemInfo !== undefined) {
          if(itemInfo.itemType == 2){
            let itemAllCustomizationInfo = this.getCustomizationDetail(itemInfo);
            isCust = this.validateRecentItemCustomization(itemInfo, reItem, itemAllCustomizationInfo);
            if (!isCust) {
                reItem.customizations = null;
            }
            itemCustomization = this.getRecentOrderCustomization(itemInfo, reItem, itemAllCustomizationInfo);
          }
          if(itemInfo.itemType == 3){
            let itemAllComboOptionInfo = this.getComboOptionDetail(itemInfo);
            isCust = this.validateRecentItemCustomization(itemInfo, reItem, itemAllComboOptionInfo);
            if (!isCust) {
                reItem.customizations = null;
            }
            itemCustomization = this.getRecentOrderComboCustomization(itemInfo, reItem, itemAllComboOptionInfo);
          }

        } else {
            itemInfo = reItem;
        }
        if(itemInfo.itemType==3){
          item = {
            itemId: 0,
            itemType:itemInfo.itemType,
            ooSaleItemId: itemInfo.id, saleItemId: itemInfo.saleItemId, quantity: reItem.qty, name: (itemInfo.name !== undefined) ? itemInfo.name : '',
            fixedPrice: 0, totalPrice: (itemInfo.fixedPrice + reItem.customizationUnitPriceWithTax) * reItem.qty,
            unitPriceWithTax: 0, customizationUnitPriceWithTax: reItem.customizationUnitPriceWithTax,
            isCustomized: (itemCustomization.length > 0) ? true : false, 
            customization: [],
            comboOptions:itemCustomization,
            remarks: reItem.remarks,
            taxCalculationMethod: itemInfo.taxCalculationMethod, tax1Percentage: itemInfo.tax1Percentage,
            netCustomizationTotal: 0,
            customizationTotalTax:0,
            netTotal: 0, totalTax: 0,
            customizationOfferAmount: 0,
            offerAmount: 0
        };
        }else{
        item = {
            itemId: 0,
            itemType:itemInfo.itemType,
            ooSaleItemId: itemInfo.id, saleItemId: itemInfo.saleItemId, quantity: reItem.qty, name: (itemInfo.name !== undefined) ? itemInfo.name : '',
            fixedPrice: itemInfo.fixedPrice, totalPrice: (itemInfo.fixedPrice + reItem.customizationUnitPriceWithTax) * reItem.qty,
            unitPriceWithTax: itemInfo.unitPriceWithTax, customizationUnitPriceWithTax: reItem.customizationUnitPriceWithTax,
            isCustomized: (itemCustomization.length > 0) ? true : false, 
            customization: itemCustomization,
            comboOptions:[],
            remarks: reItem.remarks,
            taxCalculationMethod: itemInfo.taxCalculationMethod, tax1Percentage: itemInfo.tax1Percentage,
            netCustomizationTotal: 0,
            customizationTotalTax:0,
            netTotal: 0, totalTax: 0,
            customizationOfferAmount: 0,
            offerAmount: 0
        };
      }
        if (!this.orderPriceValidation(itemInfo, reItem)) {
            item.hasWarning = true;
            item.warningMessage = itemInfo.name + ' price has been changed. \n';
        }
        if (!isCust) {
            if (item.warningMessage === undefined) {
                item.warningMessage = '';
            }
            item.hasWarning = true;
            item.warningMessage += 'Customisation options are found changed from last time. Please re-customise the item.';
        }
        if (itemCustomization.length > 0) {
          if(itemInfo.itemType==3){
            this.setCombo(item);
          }else{
            this.setCustomization(item);
          }
        }
        this.taxService.reCalculateTax(item);
        this.addItem(item);
    }

}

/**
 *
 * @param itemInfo
 * @param reItem
 * validate recent item customization only for selected customization option values.
 */
validateRecentItemCustomization(itemInfo: any, reItem: any, itemAllCustomizationInfo:any) {
    let isValid = true;
    if (reItem.customizations !== null) {
        if (itemInfo.isCustomizable === 1) {
            if (itemInfo.itemCustOptionIds !== null && itemInfo.itemCustOptionIds !== undefined && itemInfo.itemCustOptionIds.length > 0) {

                for (let cust of reItem.customizations) {
                    /** check recent item customization option id  is present in session customization option master data */
                    const optIndex = itemAllCustomizationInfo.findIndex((e:any) => e.custOptId === cust.optionid);
                    if (optIndex > -1) {
                        let isInMainItemCustOptionIds = itemInfo.itemCustOptionIds.some((ico:any) => ico === cust.optionid);
                        /**recent item customization option id is not present in main saleitem(session) option id list */
                        if (!isInMainItemCustOptionIds) {
                            isValid = false;
                            return isValid;
                        } else {
                            /**fetch session customization option value  based on recent item selected customization option value*/
                            let optVal = itemAllCustomizationInfo[optIndex].optionValue.find((ov:any) => ov.id === cust.optionValueid);
                            if (optVal !== null && optVal !== undefined) {
                                /**comparing the prices of recent optionValue and  session containing optionvalue's of customization master*/
                                if (optVal.fixedPrice !== cust.fixedPrice) {
                                    isValid = false;
                                    return isValid;
                                } else {
                                    // if(!this.isValidCustOptVal(optVal)){
                                    //     isValid = false;
                                    //     return isValid;
                                    // }
                                }

                            } else {
                                isValid = false;
                                return isValid;
                            }
                        }
                    } else {
                        isValid = false;
                        return isValid;
                    }
                }

            } else {
                /** main session saleitem information has no option Ids  but recent item customization has value*/
                if (reItem.customizations !== null) {
                    isValid = false;
                    return isValid;
                }

            }
        } else {
            isValid = false;
            return isValid;
        }
    }
    return isValid;
}

/**
 *
 * @param custOptVal Check customization is valid
 * @returns
 */
// isValidCustOptVal(custOptVal) {
//     let isValid = true;
//     this.filteredSaleItemListData = this.sessionStorage.get(SESSION_FILTERED_SALE_ITEM_LIST);
//     this.outOfStockItemIds = this.sessionStorage.get(SESSION_OUTOFSTOCK_ITEM_LIST);
//     this.outOfStockItemInfo = this.filteredSaleItemListData.filter(fs => this.outOfStockItemIds.findIndex(os => fs.id === os) >= 0);
//     if (custOptVal.saleItemId !== null) {
//         isValid = this.filteredSaleItemListData.some(oi => custOptVal.saleItemId === oi.saleItemId);
//         if (isValid && this.outOfStockItemInfo !== undefined && this.outOfStockItemInfo.length > 0) {
//             isValid = !this.outOfStockItemInfo.some(oi => custOptVal.saleItemId === oi.saleItemId);
//         }
//     }

//     // if(custOptVal.saleItemId !== null && this.outOfStockItemInfo !== undefined && this.outOfStockItemInfo.length > 0){
//     //   isValid = !this.outOfStockItemInfo.some(oi => custOptVal.saleItemId === oi.saleItemId);
//     // }

//     return isValid;
// }

/**
 *
 * @param itemInfo
 * @param item
 * @returns
 * In the price validation , compare prices of master saleitem and reordered item.
 */
orderPriceValidation(itemInfo:any, item:any) {
    let noPriceChange = true;
    if (itemInfo.fixedPrice !== item.fixedPrice) {
        noPriceChange = false;
    }
    return noPriceChange;
}

getRecentOrder(customerId:any){
  return this.http.get(this.recentOrderUrl +'/'+ customerId)
  // const recentOrder=
}

setRecentOrderComboCustomization(ov: any, reCoOption: any, comboOptionCustomization:any){
  // if (itemInfo.itemType == 3) {
    if (reCoOption.comboOptions !== null) {
      // let comboCustomizations = itemAllCustomizationInformation;
      comboOptionCustomization.forEach((coc: any) => {
        // var customizationItem = eico;
        // for (var rco of reCoOption.comboOptions) {
          if (reCoOption.customizations !== null) {
            for (var rcc of reCoOption.customizations) {
              for (var ov of coc.optionValue) {
                ov.totalPrice = 0;
                ov.netTotal = 0;
                ov.totalTax = 0;
                if (ov.custOptId === rcc.optionid) {
                  if(ov.id === rcc.optionValueid){
                  ov.isSelected = 1
                  }
                  else {
                  ov.isSelected = 0
                }
                }
                
              }
            }
          }
        // }
        // customization.push(customizationItem);
      });
    }
    return comboOptionCustomization;
  // }
}
/**
 *
 * @param itemInfo
 * @param reItem
 * @returns
 * mark selected customization item  in all customization list
 */
getRecentOrderCustomization(itemInfo: any, reItem: any, itemAllCustomizationInformation:any) {
    let customization :any= [];
  
    if (reItem.customizations !== null) {
        let itemAllCustomizationInfo = itemAllCustomizationInformation;
        itemAllCustomizationInfo.forEach((element:any) => {
            const customizationItem = element;
            for (let rc of reItem.customizations) {
                if (customizationItem.custOptId === rc.optionid) {
                    for (let ov of customizationItem.optionValue) {
                        if (ov.id === rc.optionValueid) {
                            ov.isSelected = 1;
                        } else {
                            ov.isSelected = 0;
                        }
                    }
                }
            }
            customization.push(customizationItem);
        });
    }
    return customization;
}

/**
 *
 * @param itemInfo
 * @param reItem
 * @returns
 * mark selected customization item  in all customization list
 */
getRecentOrderComboCustomization(itemInfo: any, reItem: any, itemAllComboCustomizationInformation:any) {
  let comboCustomization :any= [];
  let customizationUnitPriceWithTax = 0;
  if (reItem.comboOptions !== null) {
      let itemAllComboCustomizationInfo = itemAllComboCustomizationInformation;
      itemAllComboCustomizationInfo.forEach((element:any) => {
          const comboItem = element;
          for (let rc of reItem.comboOptions) {
              if (comboItem.comboOptId === rc.optionid) {     
                  for (let ov of comboItem.optionValue) {
                      if (ov.id === rc.optionValueid) {
                          ov.isSelected = 1;
                          let itemAllCustomizationInfo=this.getCustomizationDetail(ov.saleItem);
                          if(itemAllCustomizationInfo !== null && itemAllCustomizationInfo.length !== 0) {
                          ov.customization=this.setRecentOrderComboCustomization(ov, rc, itemAllCustomizationInfo);
                          }
                          if(rc.customizations!== null){
                            ov.customizationUnitPriceWithTax=this.getCustomizationUnitPriceWithTax(rc.customizations)
                            ov.netCustomizationTotal = ov.customizationUnitPriceWithTax
                          }

                      } else {
                          ov.isSelected = 0;
                      }
                  }
              }
          }
          comboCustomization.push(comboItem);
      });
  }
  return comboCustomization;
}

updateCustomization(comboItemCustomization:any){
  let allCustmizationMasterDtl = JSON.parse(this.storageService.loadFromSession(SESSION_MASTER_CUSTOMIZATION));
  console.log(allCustmizationMasterDtl);
  console.log(comboItemCustomization);
  // customizationDetail = allCustmizationMasterDtl.filter((ac:any) =>
  //     saleItemInfo.itemCustOptionIds.find((e:any) => e === ac.custOptId)
  // );
}

getCustomizationUnitPriceWithTax(customizations:any):any{
  var customizationUnitPriceWithTax=0
  for (const value of customizations) {
      customizationUnitPriceWithTax += value.unitPriceWithTax;
  }
  return customizationUnitPriceWithTax;
}
  /**
   * Sets the related item id for buy item and offer item
   * @param buyItem 
   * @param offerItem 
   */
  setRelatedItem(buyItemId:any,offerItemId:any):void{

    var buyItem = this._cart.items?.find((ci:any)=>ci.itemId==buyItemId);
    var offerItem = this._cart.items?.find((ci:any)=>ci.itemId==offerItemId);


    if(buyItem)
      buyItem.relatedItemId?.push(offerItemId);

    if(offerItem)
      offerItem.relatedItemId?.push(buyItemId);
    
    this.setToSession();
  }

    /**
   * Unsets the related item id f
   * @param itemId
   */
    unsetRelatedItem(itemId:any):void{

      var cartItem = this._cart.items?.find((ci:any)=>ci.itemId==itemId);
       
      if(cartItem)
        cartItem.relatedItemId=[];
      
      this.setToSession();
    }

/**
 * Checks for item in cart
 * @param item
 * @returns
 */
// isItemInCart(item: any): boolean {
//     var isItemInCart = false;
//     isItemInCart = (this.cart.items.findIndex(o2 => item.saleItemId === o2.saleItemId) >= 0);
//     return isItemInCart;
// }
/**
 * each item quantity in cart
 * @param item
 * @returns
 */
eachItemQuantityInCart(item:any) {
    var sameItemInCartArray :any= [];
    sameItemInCartArray = (this.cart.items?.filter(o2 => item.saleItemId === o2.saleItemId));
    let quantity = 0;
    for (let it of sameItemInCartArray) {
        quantity += it.quantity;
    }
    return quantity;
}

/**
 * @param saleItemInfo
 * @returns
 *Get customization detail based on sale item info.
 * Sale item info means sale item information of a particular saleItem id.
 * Fetch customization detail of that item by using itemCustOptionIds and
 * allCustmizationMasterDtl.
 */
// getCustomizationDetail(saleItemInfo: any): any {
//     let customizationDetail = [];
//     if (saleItemInfo.itemCustOptionIds != null && saleItemInfo.itemCustOptionIds.length > 0) {
//         let allCustmizationMasterDtl = this.sessionStorage.get(SESSION_MASTER_CUSTMIZATION);
//         customizationDetail = allCustmizationMasterDtl.filter(ac =>
//             saleItemInfo.itemCustOptionIds.find(e => e === ac.custOptId)
//         );

//         if (customizationDetail !== undefined && customizationDetail.length > 0) {
//             customizationDetail = JSON.parse(JSON.stringify(customizationDetail));
//         }
//     }
//     return customizationDetail;
// }

/**
 * check customization option value is valid or not
 * if not valid, show warning message, remove option values of that item.
 * @param cartItem
 * @returns
 */

// isitemAndCustOptInValid(cartItem) {
//     let isValidCust = true;
//     let custOptData = this.getSelectedCustomizationOptionValueData(cartItem);
//     for (let custOpt of custOptData) {
//         isValidCust = this.isValidCustOptVal(custOpt);
//         if (!isValidCust) {
//             cartItem.customizations = null;
//             cartItem.customization=[];
//             cartItem.customizationUnitPriceWithTax=0;
//             cartItem.isCustomized = false;
//             if (cartItem.warningMessage === undefined) {
//                 cartItem.warningMessage = '';
//             }
//             cartItem.hasWarning = true;
//             cartItem.warningMessage += 'Customisation options are found changed from last time. Please re-customise the item.';
//         }
//     }
//     if(cartItem.hasWarning === undefined){
//         cartItem.hasWarning = false;
//     }

//     return cartItem.hasWarning;
// }

}
